import { observer } from "mobx-react-lite";
import React from "react";
import { useContext, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Menu,
  Divider,
  List,
  Segment,
  Dimmer,
  Loader,
  DropdownProps,
  Dropdown,
  Button,
} from "semantic-ui-react";
import { AdvertStatus } from "../../options/AdvertStatus";
import { RootStoreContext } from "../../stores/RootStoreContext";
import Products from "../products/Products";
import AdminCampaignListItem from "./AdminCampaignListItem";

const AdminCampaigns = () => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const {
    load,
    hasMore,
    loading,
    items,
    dispose,
    selectedStatuses,
    setSelectedStatuses,
    search,
  } = context.adminCampaignStore;
  const { previewAd, preview, setPreview, setPreviewAd } =
    context.advertiseSetupStore;

  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    load();

    return () => {
      dispose();
    };
  }, [load, dispose]);

  return (
    <div className="simply-paddedPage">
      <Menu secondary size={"small"} style={{ margin: "5px 0" }}>
        {!preview && (
          <Menu.Item style={{ padding: "0px" }}>
            <Dropdown
              placeholder={"Filter status"}
              value={selectedStatuses}
              closeOnBlur
              selection
              multiple
              clearable
              search
              onChange={(ev, data: DropdownProps) => {
                setSelectedStatuses((data?.value ?? []) as number[]);
                if (!search) return;
                if (timer != null) {
                  clearTimeout(timer);
                  setTimer(0);
                }

                setTimer(
                  setTimeout(
                    (callback: () => void, text: string) => {
                      callback();
                      clearTimeout(timer);
                    },
                    500,
                    search
                  )
                );
              }}
              options={AdvertStatus?.map((x) => ({
                key: x.key,
                text: x.text,
                value: x.value,
              }))}
            />
          </Menu.Item>
        )}
        {preview && (
          <Menu.Menu position="right">
            <Menu.Item style={{ padding: "0px" }}>
              <Button
                basic
                color="blue"
                onClick={() => {
                  setPreviewAd(undefined);
                  setPreview(undefined);
                }}
              >
                Exit Preview
              </Button>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
      <Divider />
      {!loading || items.length > 0 ? (
        preview && previewAd ? (
          <Products></Products>
        ) : (
          <div>
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={items.length} //This is important field to render the next data
              next={() => load()}
              hasMore={hasMore}
              loader={loading ? <h4>Loading...</h4> : <div />}
            >
              <List
                divided
                relaxed
                style={isMobile ? { padding: "7px" } : undefined}
              >
                {items.map((x) => (
                  <AdminCampaignListItem key={x.id} x={x} />
                ))}
              </List>
            </InfiniteScroll>
          </div>
        )
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
};

export default observer(AdminCampaigns);
