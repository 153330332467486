import { observer } from 'mobx-react-lite'
import React, { Fragment, useContext, useEffect } from 'react'
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Button, Divider, Grid, Header, Icon, Image, Label, Message, Popup, Segment, Table, TableCell } from 'semantic-ui-react';
import { formatRelative } from 'date-fns';
import { getImageUrlPlain } from '../../../helpers/productImage';
import { getMarketImageUrl } from '../../../helpers/supermarketImage';
import { IAssociationForScore } from '../../../interfaces/association';
import { IProduct } from '../../../interfaces/product';
import Associations from '../../associations/Associations';
import { ButtonTypes } from '../../confirmationModal/functions/ButtonTypes';
import { currencyFormat } from '../../products/functions/productHelper';
import NewAssociationWidget from './NewAssociationWidget';

const OneByOne = () => {
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const { closeConfirmationalModal, openConfirmationModal } =
        context.confirmationModalStore;
    const { approvePossibleGroupRelation } = context.orphanStore;
    const {
        loadNext,
        dispose,
        association,
        loading,
        clickRow,
        approveAssociation,
        toggleExpanded,
        expandedIds,
        removeRealGroupRelation,
        mergeAssociation,
        removeProductRelation,
        deletePossibleAssociation,
        toggleView,
        existingGroupsView,
    } = context.possibleAssociationsV2Store;

    useEffect(() => {
        loadNext();

        return () => {
            (window as any).groupForm = null;
        };
    }, [loadNext, dispose]);

    return (
        <Segment>
            <Segment>
                {!loading && !association && (
                    <Message info content="No pending possible associations" />
                )}
                {association && (
                    <Fragment>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column textAlign="right">
                                    <div className="ui tiny compact text labeled icon menu">
                                        <span
                                            title="Merge to group"
                                            className="item"
                                            onClick={() => toggleView()}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="purple large fitted icon paperclip"
                                            ></i>
                                            Merge to group
                                        </span>
                                        <span
                                            title="Remove Product"
                                            className="item"
                                            onClick={() => {
                                                openConfirmationModal(
                                                    "Confirm product info deletion",
                                                    "Are you sure you want to delete this product?",
                                                    () =>
                                                        deletePossibleAssociation().then(() =>
                                                            closeConfirmationalModal()
                                                        ),
                                                    "Cancel",
                                                    "Delete",
                                                    ButtonTypes.None,
                                                    ButtonTypes.Negative
                                                );
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="red large fitted icon trash alternate"
                                            ></i>
                                            Delete
                                        </span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {existingGroupsView && (
                            <NewAssociationWidget
                                loading={loading}
                                approveAssociation={approveAssociation}
                                products={association.products}
                            />
                        )}
                    </Fragment>
                )}
            </Segment>
            <br />
            {/* {existingGroupsView && (
                <Fragment>
                    <div>
                        <b>Labels : </b>
                        {association?.labels?.join(", ")}
                    </div>
                    <div>
                        <b>Measurements : </b>
                        <List horizontal>
                            {association?.units?.map((x: IAssociationUnit) => (
                                <Label
                                    color="blue"
                                    content={x.value}
                                    onClick={() => {
                                        clickUnit(x.value);
                                    }}
                                />
                            ))}
                        </List>
                    </div>
                    <div>
                        <b>Units : </b>
                        <List horizontal>
                            {association?.units?.map((x: IAssociationUnit) => (
                                <Label
                                    color="olive"
                                    content={x.unit}
                                    onClick={() => {
                                        measurementUnitClick(x.unit);
                                    }}
                                />
                            ))}
                        </List>
                    </div>
                </Fragment>
            )} */}
            <Segment>
                <Table basic="very" celled>
                    <Table.Body>
                        {association &&
                            association.products?.map((f: IProduct, i: number) => (
                                <Table.Row key={f.id}>
                                    <Table.Cell>
                                        <Header as="h4" image>
                                            <Popup
                                                trigger={
                                                    <Image
                                                        src={getImageUrlPlain(f.marketId, f.imageUrl)}
                                                        alt="Product Logo"
                                                        rounded
                                                        size="mini"
                                                        onClick={() =>
                                                            clickRow(
                                                                undefined,
                                                                getImageUrlPlain(f.marketId, f.imageUrl)
                                                            )
                                                        }
                                                    />
                                                }
                                                content={
                                                    <Image
                                                        src={
                                                            getImageUrlPlain(f?.marketId, f?.imageUrl) ??
                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                        }
                                                        onError={({ currentTarget }: any) => {
                                                            currentTarget.onerror = null;
                                                            currentTarget.src =
                                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                        }}
                                                        alt="Product Logo"
                                                        rounded
                                                    />
                                                }
                                            />
                                            <Header.Content
                                                onClick={() => clickRow(f.itemDesc, undefined)}
                                            >
                                                {f.itemDesc}
                                            </Header.Content>
                                        </Header>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {formatRelative(
                                            new Date(f.lastUpdated * 1000),
                                            new Date(),
                                            {}
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {f.discontinued && (
                                            <p>
                                                <Label color="red" basic content="Discontinued" />
                                            </p>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Fragment>
                                            <p>
                                                <b>SKU :</b> {f.sku ?? "-"}
                                            </p>
                                            <p>
                                                <b>Internal Id :</b> {f.internalId}
                                            </p>
                                        </Fragment>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Fragment>
                                            <Image
                                                alt="Product Logo"
                                                size="tiny"
                                                src={getMarketImageUrl(
                                                    markets?.filter((z) => z.type_id === f.marketId)[0]
                                                )}
                                            />
                                            <p>
                                                {
                                                    markets?.filter((z) => z.type_id === f.marketId)[0]
                                                        .title
                                                }
                                            </p>
                                        </Fragment>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <div
                                            style={{
                                                marginBottom: "0.5em",
                                                fontSize: "17px",
                                                fontWeight: "bolder",
                                            }}
                                        >
                                            <Icon name="euro" />
                                            {currencyFormat(f.currentPrice, false)}
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            circular
                                            color="red"
                                            icon="minus"
                                            size="tiny"
                                            onClick={() => removeProductRelation(f.id)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>

            <br />
            {existingGroupsView &&
                association?.orphanProducts &&
                (association?.orphanProducts.length ?? 0) > 0 && (
                    <div>
                        <Header content="Orphan Products" />
                        <Divider />
                        <Segment>
                            <Table basic="very" celled>
                                <Table.Body>
                                    {association?.orphanProducts &&
                                        association.orphanProducts.map((f) => (
                                            <Table.Row key={f.id}>
                                                <Table.Cell>
                                                    <Header as="h4" image>
                                                        <Popup
                                                            trigger={
                                                                <Image
                                                                    src={getImageUrlPlain(
                                                                        f.marketId,
                                                                        f.imageUrl
                                                                    )}
                                                                    alt="Product Logo"
                                                                    rounded
                                                                    size="mini"
                                                                />
                                                            }
                                                            content={
                                                                <Image
                                                                    src={
                                                                        getImageUrlPlain(
                                                                            f?.marketId,
                                                                            f?.imageUrl
                                                                        ) ??
                                                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                    }
                                                                    onError={({ currentTarget }: any) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src =
                                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                    }}
                                                                    alt="Product Logo"
                                                                    rounded
                                                                />
                                                            }
                                                        />
                                                        <Header.Content>{f.itemDesc}</Header.Content>
                                                    </Header>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {formatRelative(
                                                        new Date(f.lastUpdated * 1000),
                                                        new Date(),
                                                        {}
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {f.discontinued && (
                                                        <p>
                                                            <Label
                                                                color="red"
                                                                basic
                                                                content="Discontinued"
                                                            />
                                                        </p>
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Fragment>
                                                        <p>
                                                            <b>SKU :</b> {f.sku ?? "-"}
                                                        </p>
                                                        <p>
                                                            <b>Internal Id :</b> {f.internalId}
                                                        </p>
                                                    </Fragment>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Fragment>
                                                        <Image
                                                            alt="Product Logo"
                                                            size="tiny"
                                                            src={getMarketImageUrl(
                                                                markets?.filter(
                                                                    (z) => z.type_id === f.marketId
                                                                )[0]
                                                            )}
                                                        />
                                                        <p>
                                                            {
                                                                markets?.filter(
                                                                    (z) => z.type_id === f.marketId
                                                                )[0].title
                                                            }
                                                        </p>
                                                    </Fragment>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <div
                                                        style={{
                                                            marginBottom: "0.5em",
                                                            fontSize: "17px",
                                                            fontWeight: "bolder",
                                                        }}
                                                    >
                                                        <Icon name="euro" />
                                                        {currencyFormat(f.currentPrice, false)}
                                                    </div>
                                                </Table.Cell>
                                                <TableCell>
                                                    <Button
                                                        circular
                                                        color="green"
                                                        icon="add"
                                                        size="tiny"
                                                        onClick={() =>
                                                            approvePossibleGroupRelation(
                                                                association.id,
                                                                f.id
                                                            ).then(() => loadNext(association.id))
                                                        }
                                                    />
                                                </TableCell>
                                            </Table.Row>
                                        ))}
                                </Table.Body>
                            </Table>
                        </Segment>
                    </div>
                )}

            {!existingGroupsView && (
                <Fragment>
                    <Divider />
                    <Associations mode="group_select" />
                </Fragment>
            )}

            {existingGroupsView &&
                association &&
                (association.possibleGroups?.length ?? 0) > 0 && (
                    <Fragment>
                        <Header content="Compare" />
                        <Divider />
                        {association.possibleGroups
                            ?.slice()
                            .sort((a: IAssociationForScore, b: IAssociationForScore) =>
                                a.score < b.score ? 1 : -1
                            )
                            .map((x: IAssociationForScore) => (
                                <Segment key={x.id}>
                                    <Grid>
                                        <Grid.Row columns={1}>
                                            <Grid.Column textAlign="right">
                                                <div className="ui green tiny compact text labeled icon menu">
                                                    <span
                                                        title="Approve"
                                                        className="item"
                                                        onClick={() => {
                                                            mergeAssociation(x.id);
                                                        }}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="green chain large fitted icon"
                                                        ></i>
                                                        Approve
                                                    </span>
                                                    <span
                                                        title="Remove Group"
                                                        className="item"
                                                        onClick={() => {
                                                            openConfirmationModal(
                                                                "Confirm relation removal",
                                                                "Are you sure you want to remove this relation?",
                                                                () =>
                                                                    removeRealGroupRelation(x.id).then(() =>
                                                                        closeConfirmationalModal()
                                                                    ),
                                                                "Cancel",
                                                                "Remove",
                                                                ButtonTypes.None,
                                                                ButtonTypes.Negative
                                                            );
                                                        }}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className="red large fitted icon trash alternate"
                                                        ></i>
                                                        Remove Group
                                                    </span>

                                                    <span
                                                        className="item"
                                                        title="Expand/Compress"
                                                        onClick={() => toggleExpanded(x.id)}
                                                    >
                                                        <i
                                                            aria-hidden="true"
                                                            className={`grey large fitted icon ${expandedIds.findIndex(
                                                                (z: string) => z === x.id
                                                            ) > -1
                                                                ? "compress"
                                                                : "expand"
                                                                }`}
                                                        ></i>
                                                        {expandedIds.findIndex(
                                                            (z: string) => z === x.id
                                                        ) > -1
                                                            ? "Compress"
                                                            : "Expand"}
                                                    </span>
                                                </div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Segment.Group>
                                        <Segment.Group horizontal>
                                            <Segment>
                                                <p>
                                                    <b>Title :</b> {x.title}
                                                </p>
                                            </Segment>
                                            <Segment>
                                                <p>
                                                    <b>Score :</b> {x.score}
                                                </p>
                                            </Segment>
                                        </Segment.Group>
                                        {/* <Segment.Group horizontal>
                                                <Segment>
                                                    {x.labels && (
                                                        <p>
                                                            <b>Labels :</b>
                                                            <Highlighter
                                                                caseSensitive={false}
                                                                highlightStyle={{
                                                                    background: "#ffb7b7",
                                                                }}
                                                                searchWords={association.labels ?? []}
                                                                autoEscape={true}
                                                                textToHighlight={x.labels.join(", ")}
                                                            />
                                                        </p>
                                                    )}
                                                </Segment>
                                                <Segment>
                                                    {x.units && (
                                                        <p>
                                                            <b>Units :</b>{" "}
                                                            <Highlighter
                                                                caseSensitive={false}
                                                                highlightStyle={{
                                                                    background: "#ffb7b7",
                                                                }}
                                                                searchWords={
                                                                    association.units
                                                                        ? [
                                                                            ...association.units.map(
                                                                                (m: IAssociationUnit) => m.unit
                                                                            ),
                                                                            ...association.units.map(
                                                                                (m: IAssociationUnit) => m.value
                                                                            ),
                                                                        ]
                                                                        : []
                                                                }
                                                                autoEscape={true}
                                                                textToHighlight={x.units
                                                                    .map(
                                                                        (x) => `${x.value ?? 0}/${x.unit ?? "-"}`
                                                                    )
                                                                    .join(", ")}
                                                            />
                                                        </p>
                                                    )}
                                                </Segment>
                                            </Segment.Group> */}
                                    </Segment.Group>

                                    <Segment>
                                        <Table basic="very" celled>
                                            <Table.Body>
                                                {x.products &&
                                                    x.products.map(
                                                        (f, i) =>
                                                            (i < 1 ||
                                                                expandedIds.findIndex(
                                                                    (z: string) => z === x.id
                                                                ) > -1) && (
                                                                <Table.Row key={f.id}>
                                                                    <Table.Cell>
                                                                        <Header as="h4" image>
                                                                            <Popup
                                                                                trigger={
                                                                                    <Image
                                                                                        src={getImageUrlPlain(
                                                                                            f.marketId,
                                                                                            f.imageUrl
                                                                                        )}
                                                                                        alt="Product Logo"
                                                                                        rounded
                                                                                        size="mini"
                                                                                    />
                                                                                }
                                                                                content={
                                                                                    <Image
                                                                                        src={
                                                                                            getImageUrlPlain(
                                                                                                f?.marketId,
                                                                                                f?.imageUrl
                                                                                            ) ??
                                                                                            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                                                                                        }
                                                                                        onError={({
                                                                                            currentTarget,
                                                                                        }: any) => {
                                                                                            currentTarget.onerror = null;
                                                                                            currentTarget.src =
                                                                                                `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                                                                        }}
                                                                                        alt="Product Logo"
                                                                                        rounded
                                                                                    />
                                                                                }
                                                                            />
                                                                            <Header.Content>
                                                                                {f.itemDesc}
                                                                            </Header.Content>
                                                                        </Header>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {formatRelative(
                                                                            new Date(f.lastUpdated * 1000),
                                                                            new Date(),
                                                                            {}
                                                                        )}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {f.discontinued && (
                                                                            <p>
                                                                                <Label
                                                                                    color="red"
                                                                                    basic
                                                                                    content="Discontinued"
                                                                                />
                                                                            </p>
                                                                        )}
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Fragment>
                                                                            <p>
                                                                                <b>SKU :</b> {f.sku ?? "-"}
                                                                            </p>
                                                                            <p>
                                                                                <b>Internal Id :</b> {f.internalId}
                                                                            </p>
                                                                        </Fragment>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <Fragment>
                                                                            <Image
                                                                                alt="Product Logo"
                                                                                size="tiny"
                                                                                src={getMarketImageUrl(
                                                                                    markets?.filter(
                                                                                        (z) => z.type_id === f.marketId
                                                                                    )[0]
                                                                                )}
                                                                            />
                                                                            <p>
                                                                                {
                                                                                    markets?.filter(
                                                                                        (z) => z.type_id === f.marketId
                                                                                    )[0].title
                                                                                }
                                                                            </p>
                                                                        </Fragment>
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        <div
                                                                            style={{
                                                                                marginBottom: "0.5em",
                                                                                fontSize: "17px",
                                                                                fontWeight: "bolder",
                                                                            }}
                                                                        >
                                                                            <Icon name="euro" />
                                                                            {currencyFormat(f.currentPrice, false)}
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )
                                                    )}
                                            </Table.Body>
                                        </Table>
                                    </Segment>
                                </Segment>
                            ))}
                    </Fragment>
                )}
        </Segment>
    )
}

export default observer(OneByOne)