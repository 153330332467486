import React, { useContext, useEffect, useRef } from "react";
import SearchBox from "./SearchBox";
import { RootStoreContext } from "../../stores/RootStoreContext";
import SearchSuggestionBox from "./SearchSuggestionBox";
import { observer } from "mobx-react-lite";

interface Props {
  showButtonText?: boolean;
  onSubmit?: (data: any) => void;
  scroll?: () => void;
  [key: string]: unknown;
}

const Search: React.FC<Props> = ({ onSubmit, scroll, ...props }) => {
  const context = useContext(RootStoreContext);
  const {
    setSearchTerm,
    state,
    fetchSuggestions,
    setDrawerState
  } = context.searchStore;
  const [timer, setTimer] = React.useState(0);

  let searchRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let handler = (e: any) => {
      let elementWhitelist = document.querySelector('.drawer');
      if (searchRef.current && (!searchRef.current.contains(e.target) && !elementWhitelist?.contains(e.target))) {
        setDrawerState(false);
      }
    }
    document.addEventListener("pointerdown", handler, { capture: true });

    return () => {
      document.removeEventListener("pointerdown", handler, { capture: true });
    }
  }, [searchRef, setDrawerState])
  function checkDrawerOnPost() {
    if (state.isDrawerOpen === true) {
      setDrawerState(false);
    }
  }
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (!state.isDrawerOpen) {
      setDrawerState(true);
    }
    if (timer != null) {
      clearTimeout(timer);
      setTimer(0);
    }

    setTimer(
      setTimeout(
        (callback: (text: string) => void, text: string) => {
          callback(text);
          clearTimeout(timer);
        },
        500,
        fetchSuggestions,
        event.target.value as String
      )
    );
  };
  const onClick = () => {
    fetchSuggestions(state.searchTerm);
    setDrawerState(true);
  }
  const onSearch = (e: { preventDefault: () => void }) => {
    // e.preventDefault();
    if (onSubmit) {
      onSubmit(state);
    }
    if (scroll) {
      scroll();
      checkDrawerOnPost();
    }
    // setSearchTerm("");
  };
  return (
    <div ref={searchRef}>
      <SearchBox
        onEnter={onSearch}
        onChange={handleInputChange}
        value={state.searchTerm}
        onClick={onClick}
        name="search"
        placeholder="What can we help you find?"
        buttonText="Search"
        {...props}
      />
      <SearchSuggestionBox scroll={scroll} />
    </div>
  );
};

export default observer(Search);
