import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useEffect } from "react";
import { Breadcrumb, BreadcrumbDivider, BreadcrumbSection, Dimmer, Divider, Icon, List, Loader, Menu, Popup, Segment, Table } from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { IProductListForListDto } from "../../../interfaces/productLists";
import CsvExport from "../../../common/csvExport";
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoResults } from "../../../common/NoResults";
import GlobalInventoryTableRow from "./GlobalInventoryTableRow";

interface IProps {
  item: IProductListForListDto;
}
const priceDropItems = [
  { index: 1, text: "Last 1 day" },
  { index: 2, text: "Last 2 days" },
  { index: 7, text: "Last week" },
  { index: 0, text: "All" },
]

const ProductListResults: React.FC<IProps> = ({ item }) => {
  const context = useContext(RootStoreContext);
  const { isMobile } = context.deviceStore;
  const { toggleFullScreen } = context.accountPageStore;
  const {
    downloading,
    markets,
    shopId,
    exportDataForCsv,
    selectedMarketIds,
    setSelectedMarketIds,
    setSelectedProductList,
    search,
    loading,
    discontinued,
    changeDiscontinued,
    changeDropSince,
    associations,
    loadDetail,
    hasDataMore,
    disposeData
  } = context.productListStore;

  const [timer, setTimer] = React.useState(0);
  const [selectedDrop, setSelectedDrop] = React.useState(-1);

  useEffect(() => {
    loadDetail()

    return () => {
      disposeData()
    }
  }, [loadDetail, disposeData])


  return (
    <div>
      <Menu secondary size={isMobile ? "massive" : "small"} style={{ margin: "0px 0px 0px -5px" }}>
        <Menu.Item>
          <Breadcrumb size="large">
            <BreadcrumbSection onClick={() => setSelectedProductList(undefined)}>Product Lists</BreadcrumbSection>
            <BreadcrumbDivider>/</BreadcrumbDivider>
            <BreadcrumbSection active>{item.title}</BreadcrumbSection>
          </Breadcrumb>
        </Menu.Item>
      </Menu>
      <Menu secondary size={isMobile ? "massive" : "small"} style={{ margin: "5px 0" }}>
        <Menu.Item style={{ padding: "0px" }}>
          <div className="fxFixed fxDisplay fxAlignCenter">
            <div className="catalogue-sort-by-holder">Shops |</div>
            <Popup
              on="click"
              closeOnTriggerClick
              closeOnDocumentClick
              closeOnPortalMouseLeave
              closeOnTriggerBlur
              closeOnTriggerMouseLeave
              closeOnEscape
              position="bottom center"
              trigger={
                <div className="fxFixed fxDisplay">
                  <div className="fxStrech catalogue-sort-by-selected">
                    {selectedMarketIds.length === 0 ? "All" : selectedMarketIds.length > 2 ? `${selectedMarketIds.length} shops` :
                      markets.filter(f => f.id !== shopId && !f.shutDown).filter(f => selectedMarketIds.includes(f.type_id)).map(x => x.title).join(',')}
                  </div>
                  <Icon name="angle down" />
                </div>}
              content={
                <List size="medium">
                  {markets
                    .filter(f => f.id !== shopId && !f.shutDown)
                    ?.slice()
                    .sort((a, b) => (a.title < b.title ? -1 : 1)).map((f, i) => (
                      <List.Item
                        key={f.type_id}
                        onClick={() => {
                          if (selectedMarketIds.includes(f.type_id)) {
                            setSelectedMarketIds(selectedMarketIds.filter(x => x !== f.type_id));
                          }
                          else {
                            setSelectedMarketIds([...selectedMarketIds, f.type_id])
                          }
                          // console.log(f.type_id);
                          // console.log([...selectedMarketIds, f.type_id])

                          if (!search) return;
                          if (timer != null) {
                            clearTimeout(timer);
                            setTimer(0);
                          }

                          setTimer(
                            setTimeout(
                              (callback: () => void, text: string) => {
                                callback();
                                clearTimeout(timer);
                              },
                              500,
                              search
                            )
                          );
                        }}
                        className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                      >
                        <div className={`fxDisplay fxFixed ${selectedMarketIds.includes(f.type_id) ? "ct-green-text-dark" : ""}`}  >
                          <div style={{ width: "18px", textAlign: "start" }}>{selectedMarketIds.includes(f.type_id) && <Icon name={"check"} />}</div>
                          <div style={{ marginLeft: "5px" }}>{f.title}</div>
                        </div>
                      </List.Item>
                    ))}
                </List>
              }
            />
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="fxFixed fxDisplay fxAlignCenter">
            <div className="catalogue-sort-by-holder">Price Drops Since |</div>
            <Popup
              on="click"
              closeOnTriggerClick
              closeOnDocumentClick
              closeOnPortalMouseLeave
              closeOnTriggerBlur
              closeOnTriggerMouseLeave
              closeOnEscape
              position="bottom center"
              trigger={
                <div className="fxFixed fxDisplay">
                  <div className="fxStrech catalogue-sort-by-selected">
                    {selectedDrop === -1 ? "None" : priceDropItems.filter(f => f.index === selectedDrop)[0]?.text}
                  </div>
                  <Icon name="angle down" />
                </div>}
              content={
                <List size="medium">
                  {priceDropItems.map((f, i) => (
                    <List.Item
                      key={f.index}
                      onClick={() => {
                        if (selectedDrop === f.index) {
                          setSelectedDrop(-1);
                          changeDropSince(-1)
                          return;
                        }

                        setSelectedDrop(f.index);
                        var factor = f.index;
                        if (f.index > 0) {
                          factor = ((new Date()).getTime() / 1000) - (f.index * 86400)
                        }

                        changeDropSince(factor);
                      }}
                      className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                    >
                      <div className={`fxDisplay fxFixed ${selectedDrop === f.index ? "ct-green-text-dark" : ""}`}  >
                        <div style={{ width: "18px", textAlign: "start" }}>{selectedDrop === f.index && <Icon name={"check"} />}</div>
                        <div style={{ marginLeft: "5px" }}>{f.text}</div>
                      </div>
                    </List.Item>
                  ))}
                </List>
              }
            />
          </div>
        </Menu.Item>
        <Menu.Item icon title="Include discontinued products">
          <Icon
            name="broken chain"
            color={discontinued ? "red" : "grey"}
            checked={discontinued} onClick={() => changeDiscontinued()}
            disabled={loading} />
        </Menu.Item>
        <Menu.Menu position="right" >
          <Menu.Item icon title="Export" style={{ padding: "0px" }}>
            <CsvExport
              onClick={exportDataForCsv}
              content={
                <Fragment>
                  <Icon name="file excel outline" /> Export
                </Fragment>
              }
              disabled={downloading}
              loading={downloading}
            />
          </Menu.Item>
          <Menu.Item icon title="Full screen">
            <Icon name='expand'
              color='blue'
              size='large'
              onClick={() => { toggleFullScreen() }} />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
      < Divider style={isMobile ? { padding: "0", margin: "0" } : undefined} />
      {
        !loading || associations.length > 0 ? (
          <div>
            <InfiniteScroll
              dataLength={associations.filter(f => f.products.filter(p => !p.discontinued).length > 0).length}
              next={() => loadDetail()}
              hasMore={hasDataMore}
              loader={loading ? <h4>Loading...</h4> : <div />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {associations.length > 0 ? (
                    <b></b>
                  ) : (
                    <NoResults />
                  )}
                </p>
              }
            >
              <Table celled compact>
                <Table.Header className='tableTest'>
                  <Table.Row>
                    <Table.HeaderCell>Product</Table.HeaderCell>
                    {(shopId) && <Table.HeaderCell>{markets.filter(f => f.id === (shopId))[0]?.title}</Table.HeaderCell>}
                    {(selectedMarketIds.length > 0 ? markets.filter(f => selectedMarketIds.includes(f.type_id)) : markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== (shopId) && !f.shutDown).map(x => <Table.HeaderCell key={x.id}>{x.title}</Table.HeaderCell>)}
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {(discontinued ? associations : associations
                    .filter(f => f.products.filter(p => !p.discontinued).length > 0))
                    .map((x) => (
                      <GlobalInventoryTableRow key={x.id} association={x} accountId={(shopId)!} />
                    ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        ) : (
          <Segment
            style={{
              minHeight: "55vh",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )
      }
    </div>
  );
};

export default observer(ProductListResults);
