import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { Guid } from "../../../helpers/guid";
import { ICreateUpdateAdvert } from "../../../interfaces/advert";
import { RootStore } from "../../../stores/RootStoreContext";

export class AdDetailStore {
    rootStore: RootStore;

    @observable loading: boolean = false;

    @observable currentEditingId?: string;
    @observable currentEditingItem?: ICreateUpdateAdvert;

    @action setLoading = (value: boolean) => this.loading = value
    @action setEditingId = (value?: string) => this.currentEditingId = value
    @action setEditingItem = (value?: ICreateUpdateAdvert) => this.currentEditingItem = value

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action dispose = () => {
        this.loading = false;
        this.setEditingId(undefined);
        this.setEditingItem(undefined);
    }

    @action load = async (id: string) => {
        try {
            this.setLoading(true);

            // var item = await agent.Adverts.getDetail(id);

            this.setEditingId(id);
            // this.setEditingItem(item);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action save = async (item: ICreateUpdateAdvert) => {
        if (item.startsOn instanceof (Date)) {
            item.startsOn.setHours(0, 0, 0, 0);
            item.startsOn = item.startsOn.getTime() / 1000
        }

        // if (this.currentEditingId)
        //     return agent.Adverts.update(this.currentEditingId, item);
        // else
        //     return agent.Adverts.save(item);

    };

    @action uploadFile = async (file: object) => {
        var type = (file as any).path.split('.').slice(-1)[0]

        var fileName = `${Guid.newGuid()}.${type}`;

        var response = await agent.AWS.Bucket.createPresignedUrl(fileName)
        await agent.AWS.Bucket.uploadFile(file as Blob, response)

        return fileName;
    };

}