import React, { Fragment, useContext } from "react";
import { Sidebar, Menu, Segment, Divider } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../stores/RootStoreContext";
import NavBar from "./NavBar";
import { Link } from "react-router-dom";
import { requestUserSession } from "../registration/functions/sessionFnc";
import { camalize } from "../../helpers/textHelper";
import { currencyFormat } from "../products/functions/productHelper";
import ErrorBoundary from "../../components/ErrorBoundary";

interface IProps {
  element: React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > | null;
}

const SideBarMobile: React.FC<IProps> = ({ element }) => {
  const context = useContext(RootStoreContext);
  const { sidebarVisible, setSidebarVisible } = context.menuStore;
  const { isMobile } = context.deviceStore;
  const { isLoggedIn, logout } = context.sessionStore;
  const { user, isAdmin, accountId } = context.userStore;
  const { openModal, closeModal } = context.modalStore;
  const { balance } = context.clientCreditStore;

  return (
    <Fragment>
      <NavBar />
      <Sidebar.Pushable style={{ minHeight: '100vh', maxHeight: '100vh' }}>
        <Sidebar
          as={Menu}
          animation="overlay"
          onHide={() => setSidebarVisible(false)}
          vertical
          visible={sidebarVisible}
          width="wide"
          secondary
          style={{ backgroundColor: "white" }}
        >
          {!isLoggedIn && (
            <Fragment>
              <Divider />
              <Menu.Item
                name="Sign In"
                onClick={() => {
                  requestUserSession(true, openModal, closeModal, () => {
                    closeModal();
                  });
                  setSidebarVisible(false);
                }}
                style={{ fontSize: "18px" }}
              />
              <Divider />
            </Fragment>
          )}
          {isLoggedIn && user && (
            <Fragment>
              <Divider className="no-margin" />
              <Menu.Item
                name={`Hi, ${camalize(user.firstName)}`}
                className="account-button"
                style={{ fontSize: "18px" }}
              />
              <Divider className="no-margin" />

              <Menu.Item
                name="My profile"
                as={Link}
                to="/my-profile"
                onClick={() => {
                  setSidebarVisible(false);
                }}
                style={{ fontSize: "18px" }}
              />
              {!isAdmin && (
                <Fragment>
                  <Menu.Item
                    name="Orders"
                    as={Link}
                    to="/my-orders"
                    onClick={() => {
                      setSidebarVisible(false);
                    }}
                    style={{ fontSize: "18px" }}
                  />
                  <Menu.Item
                    name="credits"
                    as={Link}
                    to="/my-credits"
                    onClick={() => {
                      setSidebarVisible(false);
                    }}
                    style={{ fontSize: "18px" }}
                  >
                    {`Credits (${currencyFormat(balance)})`}
                  </Menu.Item>
                </Fragment>
              )}
              <Divider className="no-margin" />
            </Fragment>
          )}

          <Menu.Item
            name={"Online Shop"}
            as={Link}
            to="/products"
            onClick={() => {
              // setActiveItem("prod");
              setSidebarVisible(false);
            }}
            style={{ fontSize: "18px" }}
          />
          <Menu.Item
            name="Compare"
            as={Link}
            to="/compare"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setSidebarVisible(false);
            }}
          />
          <Menu.Item
            // name=""
            as={Link}
            to="/stabbilta"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setSidebarVisible(false);
            }}
          >
            Stabbiltà
            {/* <div className="new-header-mobile">New</div> */}
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/price-drop"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setSidebarVisible(false);
            }}
          >
            Price Drop
          </Menu.Item>

          {/* <Divider className="no-margin" /> */}

          {/* <Menu.Item
            as={Link}
            to="/advertise"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setSidebarVisible(false);
            }}
          >
            Advertise
          </Menu.Item> */}
          <Menu.Item
            as={Link}
            style={{ fontSize: "18px" }}
            to={accountId ? "/my-shop" : "/online-shop"}
            onClick={() => {
              setSidebarVisible(false);
            }}
          >
            {accountId ? "My Shop" : "Join Us"}

            {/* <div className="new-header-mobile">New</div> */}
          </Menu.Item>
          {/* <Menu.Item
          name="Stores"
          as={Link}
          to="stores"
          style={{ fontSize: "18px" }}
          onClick={() => {
            setSidebarVisible(false);
          }}
        /> */}
          <Menu.Item
            name="About"
            as={Link}
            to="/about"
            style={{ fontSize: "18px" }}
            onClick={() => {
              setSidebarVisible(false);
            }}
          />
          {isLoggedIn && user && isAdmin && (
            <Fragment>
              <Divider className="no-margin" />
              <Menu.Item
                name={`Admin Panel`}
                className="account-button"
                as={Link}
                to="/adminPanel"
                style={{ fontSize: "18px" }}
                onClick={() => {
                  setSidebarVisible(false);
                }}
              />
            </Fragment>
          )}
          {isLoggedIn && user && (
            <Fragment>
              <Divider className="no-margin" />
              <Menu.Item
                name="Sign Out"
                onClick={() => {
                  logout();
                  setSidebarVisible(false);
                }}
                style={{ fontSize: "18px" }}
              />
            </Fragment>
          )}
        </Sidebar>
        <Sidebar.Pusher
          style={{ backgroundColor: "white" }}
          dimmed={sidebarVisible}
        >
          <Segment
            style={isMobile ? { padding: "0", paddingTop: "0px" } : undefined}
            basic={isMobile}
          >
            <ErrorBoundary>
              {element}
            </ErrorBoundary>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Fragment>
  );
};

export default observer(SideBarMobile);
