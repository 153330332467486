import { FormApi } from "final-form";
import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IAssociationForSave, IAssociationV2 } from "../../../interfaces/association";
import { RootStore } from "../../../stores/RootStoreContext";

export class PossibleAssociationsV2Store {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @observable loading: boolean = false;
    @observable association?: IAssociationV2 = undefined;
    @observable existingGroupsView: boolean = true;
    @observable listView: boolean = false;
    @observable possibleAssociationsCount: number = 0;
    @observable expandedIds: string[] = [];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable possAssociations: IAssociationV2[] = []
    @observable searchText: string = "";

    @action setLoading = (value: boolean) => this.loading = value;
    @action toggleListView = () => this.listView = !this.listView;
    @action setAssociation = (value?: IAssociationV2) => this.association = value;
    @action setPaCount = (value: number) => this.possibleAssociationsCount = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setPossibleAssociations = (value: IAssociationV2[]) => {
        var existing = this.possAssociations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.possAssociations.push(...missing);
    };

    @action toggleExpanded = (groupId: string) => {
        var idx = this.expandedIds.findIndex(f => f === groupId);

        if (idx > -1)
            this.expandedIds = this.expandedIds.filter(f => f !== groupId);
        else
            this.expandedIds.push(groupId);
    }

    @action oneTimeLoad = async (unassociated?: boolean) => {
        try {
            if (this.rootStore.cacheStore.markets.length !== 0) return;
            var markets = await agent.Supermarkets.get();
            this.rootStore.cacheStore.setMarkets(markets);
        } catch (error) {
            // toast.error("Oops, it looks like we are facing an issue. ")
        }
    }

    @action toggleView = () => {
        if (!this.association) return;

        const { setPossibleAssociationIdToExpand } = this.rootStore.productAssociationStore;

        setPossibleAssociationIdToExpand(this.existingGroupsView ? this.association.id : undefined);
        this.existingGroupsView = !this.existingGroupsView;
    }

    @action deletePossibleAssociation = async () => {
        if (!this.association) return;
        return agent.PossibleAssociations.v2.delete(this.association.id).then(() => this.loadNext())
    }

    @action deletePossibleAssociationListView = async (associationId: string) => {
        return agent.PossibleAssociations.v2.delete(associationId).then(() => {
            runInAction(() => this.possAssociations = this.possAssociations.filter(f => f.id !== associationId));

            if (this.possAssociations.length < 10) {
                this.load();
            }
            else
                this.setPaCount(this.possibleAssociationsCount - 1);
        });
    }

    @action removeProductRelation = async (productId: string) => {
        if (!this.association) return;

        return agent.PossibleAssociations.v2.removeRelation(this.association.id, productId).then(() =>
            this.loadNext(this.association?.id)
        )
    }

    @action removeRealGroupRelation = async (associationId: string) => {
        if (!this.association) return;

        return agent.PossibleAssociations.v2.removeGroupRelation(this.association.id, associationId).then(() => {
            runInAction(() => {
                if (!this.association) return;

                this.association.possibleGroups = this.association.possibleGroups?.filter(f => f.id !== associationId)
            })
        })
    }

    @action mergeAssociation = async (associationId: string) => {
        if (!this.association) return;

        await agent.PossibleAssociations.v2.merge(this.association.id, associationId)


        this.loadNext()
    }

    @action mergeAssociationListView = async (currentId: string, associationId: string) => {
        await agent.PossibleAssociations.v2.merge(currentId, associationId)

        runInAction(() => this.possAssociations = this.possAssociations.filter(f => f.id !== currentId));

        if (this.possAssociations.length < 10) {
            this.load();
        }
        else
            this.setPaCount(this.possibleAssociationsCount - 1);
    }

    @action approveAssociation = async (values: IAssociationForSave) => {
        if (!this.association) return;

        values.productIds = this.association.products.map((x) => x.id);

        await agent.PossibleAssociations.v2.approve(this.association.id, values)

        this.loadNext()
    }

    @action loadNext = async (associationId?: string) => {
        try {
            this.setLoading(true);

            agent.PossibleAssociations.v2.count().then((count) => this.setPaCount(count))

            var response = await agent.PossibleAssociations.v2.next(associationId);

            this.setAssociation(response);

            // if (response.units && response.units.length > 0)
            //     this.measurementUnitClick(response.units[0].unit)
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action load = async () => {
        try {
            this.setLoading(true);

            agent.PossibleAssociations.v2.count().then((count) => this.setPaCount(count))

            var response = await agent.PossibleAssociations.v2.many(this.searchText, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setPossibleAssociations(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action clickUnit = (value?: string) => {
        var form = (window as any).groupForm as FormApi;

        if (value)
            form.change('measurment', parseFloat(value)) // listeners not notified
    }

    @action measurementUnitClick = (value?: string) => {
        var form = (window as any).groupForm as FormApi;

        var val = "";
        var valueSort = value?.toLowerCase();
        // if (valueSort?.includes("kg"))
        //     val = "grams"
        // if (valueSort?.includes("klg"))
        //     val = "grams"
        // if (valueSort?.includes("gr"))
        //     val = "grams"
        // if (valueSort?.includes("grm"))
        //     val = "grams"
        // if (valueSort?.includes("grms"))
        //     val = "grams"
        if (valueSort?.includes("g"))
            val = "grams"

        // if (valueSort?.includes("lt"))
        //     val = "ml"
        // if (valueSort?.includes("ltr"))
        //     val = "ml"
        // if (valueSort?.includes("lts"))
        //     val = "ml"
        // if (valueSort?.includes("ltrs"))
        //     val = "ml"
        if (valueSort?.includes("l"))
            val = "ml"

        if (val)
            form.change('measurementUnit', val) // listeners not notified
    }

    @action clickRow = (itemDesc?: string, imageUrl?: string) => {
        var form = (window as any).groupForm as FormApi;

        form.batch(() => {
            if (itemDesc)
                form.change('title', itemDesc) // listeners not notified
            if (imageUrl)
                form.change('imageUrl', imageUrl) // listeners not notified
        })
    }

    @action dispose = () => {
        this.listView = false;
        this.setLoading(false);
        this.setAssociation(undefined);
    }
}