export const AdPanelPlatform: Array<{
    value: "mobile" | "tablet" | "desktop",
    key: "mobile" | "tablet" | "desktop",
    text: string,
}> =
    [
        {
            "value": "mobile",
            "key": "mobile",
            "text": "Mobile",
        },
        {
            "value": "tablet",
            "key": "tablet",
            "text": "Tablet",
        },
        {
            "value": "desktop",
            "key": "desktop",
            "text": "Desktop",
        },
    ]