import { SemanticCOLORS } from "semantic-ui-react"

export const AdvertStatus: Array<{
    value: number,
    key: number,
    text: string,
    color: SemanticCOLORS | undefined
}> =
    [
        {
            "value": 0,
            "key": 0,
            "text": "Opened",
            "color": "teal"
        },
        {
            "value": 1,
            "key": 1,
            "text": "In Review",
            "color": "blue"
        },
        {
            "value": 2,
            "key": 2,
            "text": "Approved",
            "color": "green"
        },
        {
            "value": 3,
            "key": 3,
            "text": "Changes Requested",
            "color": "red"
        },
        {
            "value": 4,
            "key": 4,
            "text": "Completed",
            "color": "olive"
        },
        {
            "value": 5,
            "key": 5,
            "text": "Cancelled",
            "color": "brown"
        },
    ]