export const AdImageSizes: AdImageSize[] = [
    {
        key: "12",
        dimention: "500x400px",
        place: "inline",
        devices: ["mobile", "tablet", "desktop"],
        size: "small"
    },
    {
        key: "31",
        dimention: "800x450px",
        place: "side",
        devices: ["tablet", "desktop"],
        size: "small"
    },
    {
        key: "61",
        dimention: "1600x450px",
        place: "side",
        devices: ["tablet", "desktop"],
        size: "large"
    },
]

export interface AdImageSize {
    key: string;
    dimention: string;
    devices: ("mobile" | "tablet" | "desktop")[],
    place: "side" | "inline",
    size: "small" | "large"
}