import React, { useContext } from "react";
import { Sticky, Menu, MenuItemProps } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";

interface IProps {
  stickyContext: React.Ref<any>;
}

const ShopManageMenu: React.FC<IProps> = ({ stickyContext }) => {
  const context = useContext(RootStoreContext);
  const { activeItem, setActiveItem, isTrackerDisabled, isBranchesEnabled, isDeliveryEnabled } = context.accountPageStore;
  const { isOwner, isShopAdmin, isCommercialUser } = context.userStore;
  const { editingItem } = context.onlineShopStore;

  const handleItemClick = (e: any, data: MenuItemProps) => {
    setActiveItem(data.name!);
  };

  return (
    <Sticky offset={60} context={stickyContext}>
      <Menu vertical tabular attached="top">
        {/* <Menu.Item header></Menu.Item> */}
        {(isShopAdmin || isOwner) && (
          <Menu.Item
            disabled={!isShopAdmin && !isOwner}
            name="account_details"
            key="account_details"
            content={"Account Details"}
            active={activeItem === "account_details"}
            onClick={handleItemClick}
          />
        )}
        {(isShopAdmin || isOwner) && (
          <Menu.Item
            name="billing_details"
            key="billing_details"
            content={"Billing Details"}
            active={activeItem === "billing_details"}
            onClick={handleItemClick}
          />

        )}


        {((isBranchesEnabled && isShopAdmin) || isOwner) &&
          <Menu.Item
            name="locations"
            key="locations"
            content={"Outlets"}
            active={activeItem === "locations"}
            onClick={handleItemClick}
          />}

        {(isShopAdmin || isOwner) && (
          <Menu.Item
            disabled={(!editingItem?.isPublished ?? true) && !isOwner}
            name="users"
            key="users"
            content={"Users"}
            active={activeItem === "users"}
            onClick={handleItemClick}
          />
        )}
        <Menu.Item
          key="inventory"
          name="inventory"
          content={"Inventory"}
          active={activeItem === "inventory"}
          onClick={handleItemClick}
        />
        {(isShopAdmin || isOwner || isCommercialUser) && !isTrackerDisabled && (
          <Menu.Item
            disabled={isTrackerDisabled}
            key="price_tracker"
            name="price_tracker"
            content={"Price Tracker"}
            active={activeItem === "price_tracker"}
            onClick={handleItemClick}
          />
        )}
        {(isShopAdmin || isOwner || isCommercialUser) && !isTrackerDisabled && (
          <Menu.Item
            disabled={isTrackerDisabled}
            key="global_inventory"
            name="global_inventory"
            content={"Global Inventory"}
            active={activeItem === "global_inventory"}
            onClick={handleItemClick}
          />
        )}
        {(isShopAdmin || isOwner || isCommercialUser) && !isTrackerDisabled && (
          <Menu.Item
            disabled={isTrackerDisabled}
            key="product_lists"
            name="product_lists"
            content={"Product Lists"}
            active={activeItem === "product_lists"}
            onClick={handleItemClick}
          />
        )}
        {isDeliveryEnabled && <Menu.Item
          key="orders"
          name="orders"
          content={"Orders"}
          active={activeItem === "orders"}
          onClick={handleItemClick}
        />}
        {/* {editingItem?.affiliate && <Menu.Item
          key="bundles"
          name="bundles"
          content={"Bundles"}
          active={activeItem === "bundles"}
          onClick={handleItemClick}
        />} */}
        {editingItem?.affiliate && <Menu.Item
          key="sales"
          name="sales"
          content={"Sales"}
          active={activeItem === "sales"}
          onClick={handleItemClick}
        />}
      </Menu>
    </Sticky>
  );
};

export default observer(ShopManageMenu);
