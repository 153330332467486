import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { IAdverForAdminList } from "../../../interfaces/advert";
import { RootStore } from "../../../stores/RootStoreContext";

export class AdminCampaignStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable processingCampaign: boolean = false;
    @observable items: IAdverForAdminList[] = [];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable selectedStatuses: number[] = [1, 2];

    @action setLoading = (value: boolean) => { this.loading = value };
    @action setProcessingCampaign = (value: boolean) => { this.processingCampaign = value };
    @action setItems = (value: IAdverForAdminList[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action setHasMore = (value: boolean) => { this.hasMore = value };
    @action setSelectedStatuses = (value: number[]) => { this.selectedStatuses = value };

    @action load = async () => {
        try {
            this.setLoading(true);

            var response = await agent.AdminAds.getCampaigns(this.selectedStatuses, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setItems(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };

    @action approveCampaign = (id: string) => {
        this.setProcessingCampaign(true)
        agent.AdminAds.approve(id).then(() => {
            var idx = this.items.findIndex(f => f.id === id)
            runInAction(() => {
                this.items[idx].status = 2;
            })
            this.setProcessingCampaign(false)
        }).catch((ex) => {
            // toast.error(JSON.stringify(ex));
            this.setProcessingCampaign(false)
        })
    }

    @action completeCampaign = (id: string) => {
        this.setProcessingCampaign(true)
        agent.AdminAds.complete(id).then(() => {
            var idx = this.items.findIndex(f => f.id === id)
            runInAction(() => {
                this.items[idx].status = 4;
            })
            this.setProcessingCampaign(false)
        }).catch((ex) => {
            // toast.error(JSON.stringify(ex));
            this.setProcessingCampaign(false)
        })
    }

    @action dispose = () => {
        this.setLoading(false);
        this.items = [];
        this.hasMore = true;
        this.currentPage = 0
        this.rootStore.advertiseSetupStore.preview = undefined
        this.rootStore.advertiseSetupStore.previewAd = undefined
    }

    @action search = async (text: string) => {
        try {
            this.setItems([]);
            this.setCurrentPage(0)

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };


    @action cancelCampaignAdmin = (id: string, values: any) => {
        return agent.AdminAds.cancel(id, values).then(() => {
            var idx = this.items.findIndex(f => f.id === id)
            runInAction(() => {
                this.items[idx].status = 5;
                this.items[idx].cancelReason = values['cancelReason']
                this.items[idx].cancelDescription = values['cancelDescription']
            })
            this.rootStore.modalStore.closeModal();
        })
            .catch((ex) => { })
            .finally(() => {
                this.setProcessingCampaign(false)
            })
    }

}