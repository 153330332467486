import { action, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { ICatalogueForOrderList } from "../../../interfaces/catalogue";
import { Brand, SearchPost, SortByTypes } from "../../../interfaces/search";
import { CategoryNode } from "../../../interfaces/categories";

export class ShoppingCatalogueStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    sleepTime: number = 500;
    @observable loading: boolean = false;
    @observable internal_loading: boolean = false;
    @observable clearing: boolean = false;
    @observable sidebarOpen: boolean = false;
    @observable currentPage: number = 0;
    @observable search_bar_clicked?: string = undefined;
    @observable hasMore: boolean = true;
    @observable products: ICatalogueForOrderList[] = []
    @observable searchText?: string = "";
    @observable sortBy?: SortByTypes = "min_price_asc";
    @observable lastClickedId?: string = "";
    @observable selectedCategories?: string[] = [];
    @observable selectedBrands?: string[] = [];
    @observable selectedWords?: string[] = [];
    @observable selectedBrands_obj: Brand[] = []
    @observable expandedCategories: string[] = []
    @observable suggestions: string[] = []
    @observable available_brands: string[] = []
    @observable force_search: boolean = false;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setAvailableBrands = (value: string[]) => this.available_brands = value;
    @action setInternalLoading = (value: boolean) => this.internal_loading = value;
    @action setClearing = (value: boolean) => this.clearing = value;
    @action setForce = (value: boolean) => this.force_search = value;
    @action setSortBy = async (value: SortByTypes) => {
        if (this.sortBy !== value) {
            this.sortBy = value
            this.setLoading(true);
            this.setClearing(true);
            await this.sleep(this.sleepTime);
            this.setSuggestions([]);
            this.resetProducts([]);
            this.setCurrentPage(0);
            this.setForce(false);
            this.load();
        }

    };
    @action setSidebarOpen = (value: boolean) => this.sidebarOpen = value;
    @action setLastClickedId = (value: string) => this.lastClickedId = value;
    @action setSuggestions = (value: string[]) => this.suggestions = value;
    @action setCurrentPage = (value: number) => {
        this.currentPage = value;
        if (value === 0)
            this.hasMore = true;
    }
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action resetProducts = (value: ICatalogueForOrderList[]) => this.products = value;
    @action setExpandedCategories = (value: string[]) => this.expandedCategories = value;
    @action setProducts = (value: ICatalogueForOrderList[]) => {
        var existing = this.products.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.products.push(...missing);
    };
    @action setBrands = (value: Brand[]) => {
        this.selectedBrands_obj = value;
        // var existing = this.selectedBrands_obj.map(f => f.id)
        // var missing = value.filter(f => existing.indexOf(f.id) < 0);

        // this.selectedBrands_obj.push(...missing);
    };

    sleep = (ms: number): Promise<void> => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    @action load = async (stems?: string) => {
        if (this.internal_loading)
            return

        this.setLoading(true);
        this.setInternalLoading(true);
        try {
            if (stems) {
                this.searchText = stems
            }
            var params: SearchPost = {
                sortBy: this.sortBy,
                brandIds: this.selectedBrands,
                categoryIds: this.selectedCategories,
                searchText: this.searchText,
                wordIds: this.selectedWords,
                currentPage: this.currentPage,
                force_search: this.force_search
            }

            if ((params.brandIds?.length ?? 0) === 0 && params.searchText !== "" && this.currentPage === 0)
                agent.Catalogue.Order.get_brands(params)
                    .then((brnds) => this.setAvailableBrands(brnds.filter(f => !!f)));

            var response = await agent.Catalogue.Order.get(params);
            this.setCurrentPage(this.currentPage + 1);

            this.setHasMore(response.HasMore);
            this.setProducts(response.Items);
            this.setSuggestions(response.Suggestions); //["whatever"])//
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setInternalLoading(false);
            this.setLoading(false);
            this.setClearing(false);
        }
    };

    @action setBrandsIds = (ids: string[]) => {
        this.selectedBrands = ids;
    }

    @action removeBrand = async (brandId: string) => {
        this.setLoading(true);
        this.setClearing(true);
        await this.sleep(this.sleepTime);
        this.selectedBrands = this.selectedBrands?.filter(f => f !== brandId);
        this.selectedBrands_obj = this.selectedBrands_obj.filter(f => f.id !== brandId);
        this.setSuggestions([]);
        this.resetProducts([])
        this.setClearing(false);
        this.setCurrentPage(0)
        this.setForce(false);
        this.load();
    }

    @action removeCategory = async (categoryId: string) => {
        this.setLoading(true);
        this.setClearing(true);
        await this.sleep(this.sleepTime);
        this.selectedCategories = this.selectedCategories?.filter(f => f !== categoryId);
        this.expandedCategories = this.expandedCategories?.filter(f => f !== categoryId);
        if (this.lastClickedId === categoryId)
            this.lastClickedId = "";
        this.setSuggestions([]);
        this.resetProducts([])
        this.setClearing(false);
        this.setCurrentPage(0)
        this.setForce(false);
        this.load();
    }

    @action updateAssociationIdLocal = (productIds: string[], associationId: string) => {
        this.products = this.products.filter(f => !productIds.includes(f.id));
    }

    @action suggestion_search = (pref: "y" | "n") => {
        if (pref === "y")
            this.searchText = this.suggestions.join(' ')

        this.setSuggestions([]);
        this.resetProducts([]);
        this.setClearing(false);
        this.setCurrentPage(0)
        this.setForce(true);

        this.load(undefined);
    }

    @action search = async (
        text?: string,
        brandIds?: string[],
        categoryIds?: string[],
        wordIds?: string[],
        brands?: Brand[]
    ) => {
        this.setLoading(true);
        this.setClearing(true);
        await this.sleep(this.sleepTime);
        try {
            runInAction(() => {
                this.searchText = text
                this.selectedBrands = brandIds
                this.selectedCategories = categoryIds
                this.selectedWords = wordIds
                this.setBrands(brands ?? []);

                this.setSuggestions([]);
                this.resetProducts([]);
                this.setClearing(false);
                this.setCurrentPage(0)
                this.setForce(false);

                this.load();

            })

            if (text !== "" && text !== undefined)
                this.rootStore.analyticsStore.setSearchEvent("Catalogue", text);

        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action scroll = () => {
        const targetDiv = document.getElementsByClassName('productsContainer');
        if (!targetDiv || targetDiv.length <= 0) {
            return;
        }
        const headerOffset = (document.querySelector('header')?.offsetHeight || 70);
        const y = targetDiv[0].getBoundingClientRect().top + window.scrollY - headerOffset + 5

        window.scrollTo({
            top:
                y,
            behavior: 'smooth',
        });
    };

    @action search_from_tree = async (
        category: CategoryNode,
    ) => {
        this.scroll();
        this.setLoading(true);
        this.setClearing(true);
        await this.sleep(this.sleepTime);
        try {
            if (category.isMain) {
                if (this.selectedCategories?.includes(category.id)) {
                    this.setLastClickedId("");
                    this.selectedCategories = [];
                    this.setExpandedCategories([]);
                }
                else {
                    this.setLastClickedId(category.id);
                    this.selectedCategories = [category.id];
                    this.setExpandedCategories([category.id]);
                }
            }
            else {
                if (this.selectedCategories?.length === 1 && this.selectedCategories?.includes(category.id)) {
                    this.setLastClickedId("");
                    this.selectedCategories = [];
                    this.setExpandedCategories([])
                }
                else {
                    this.setLastClickedId(category.id);
                    this.selectedCategories = [category.id];
                    this.setExpandedCategories([...this.expandedCategories, category.id])
                }
            }

            this.setSidebarOpen(false);
            this.setSuggestions([]);
            this.resetProducts([]);
            this.setClearing(false);
            this.setCurrentPage(0)
            this.setForce(false);

            this.load();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action dispose = () => {
        this.searchText = ""
        this.setSuggestions([]);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.setForce(false);
        this.resetProducts([]);
        this.setClearing(false);
        this.setHasMore(false);
        this.selectedCategories = [];
        this.selectedBrands = [];
        this.selectedWords = [];
    }
}