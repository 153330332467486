import React, { useContext } from 'react'
import { Button, Divider, Grid, Header, Image, List, Segment } from 'semantic-ui-react';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { Link } from 'react-router-dom';

const StabbilitaModal = () => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { closeModal } = context.modalStore;

    return (
        <Grid columns={2} stackable textAlign='center'>
            <Grid.Row className='no-padding'>
                <Grid.Column className='no-padding' style={{ backgroundColor: "#26356C", minHeight: (isMobile ? "300px" : "500px") }}>
                    <Image
                        style={isMobile ? { margin: "auto", top: "30%", padding: "10px" } : { margin: "auto", top: "40%", }}
                        src={"stabbilta.png"}
                        alt="Reserve your spot" />
                </Grid.Column>

                <Grid.Column verticalAlign='top' style={isMobile ? {
                    paddingLeft: '0px',
                    paddingTop: '0px',
                    height: "100%",
                } : {
                    paddingLeft: '0px',
                    height: "100%",
                }}>
                    <Segment
                        style={isMobile ? { maxHeigh: "60vh" } : { height: "100%", }}
                        basic
                        className={`fxDisplay fxDirCol fxAlignCenter fxJustifyBetween ${isMobile ? "no-padding" : ""}`}
                    >
                        <div>
                            <Header as="h3" >
                                Please take a moment to read the following information
                            </Header>
                            <Divider />
                            <div className=' fxDirCol fxAlignCenter' style={{ overflowY: "auto", maxHeight: "500px" }}>
                                <List bulleted>
                                    <List.Item className='readable-text fxTextAlignJustify'>
                                        <b>RRP:</b> The Recommended Retail Price (RRP), is a price suggestion provided by the manufacturer or supplier of a product to retailers. This suggested price is intended to be used as a guide for setting the retail price of a product.
                                    </List.Item>
                                    <List.Item className='readable-text fxTextAlignJustify'>
                                        <b>RRP Was:</b> This reflects the Recommended Retail Price as of October 2023.
                                    </List.Item>
                                    <List.Item className='readable-text fxTextAlignJustify'>
                                        <b>RRP Now:</b> This represents the maximum Recommended Retail Price. Participating retailers who source the product from the designated importer are committed to ensuring that the price on the shelf does not exceed this limit. However, they are free to offer the product at a lower price if they choose.
                                    </List.Item>
                                    <List.Item className='readable-text fxTextAlignJustify'>
                                        <b>Compare Web Prices:</b> This allows you to compare the prices accross some retailers according to the price on their website. Next to each shop we have the date and time of the price.
                                    </List.Item>
                                    <List.Item className='readable-text fxTextAlignJustify'>
                                        <b>Importer:</b> The importer information is prominently displayed in the top left corner of the product page. By clicking on it, you will be redirected to the specific product page on <a rel="noreferrer" target='_blank' href='https://stabbilta.gov.mt/'>stabbilta.gov.mt</a>, where you can find more details.
                                    </List.Item>
                                    <List.Item className='readable-text fxTextAlignJustify darkBlueColor'>
                                        <b>Disclaimer:</b> Cheap Trolley does not have information on which specific retailers are serviced by each importer. It is important to understand that the same products may be distributed by different importers at varying Recommended Retail Prices (RRPs). The price comparison is not to be used to assume that the retailer is not following the initiative. Kindly refer to the <Link style={{ fontWeight: "bolder", textDecorationLine: "underline" }} onClick={() => closeModal()} to={"/disclaimer"}>Disclaimer</Link>,{" "}
                                        <Link style={{ fontWeight: "bolder", textDecorationLine: "underline" }} onClick={() => closeModal()} to={"/terms"}>Terms of Use</Link> and{" "}
                                        <Link style={{ fontWeight: "bolder", textDecorationLine: "underline" }} onClick={() => closeModal()} to={"/privacy-policy"}>Privacy Policy</Link> for more
                                        details.
                                    </List.Item>
                                </List>
                                <Button fluid color='blue' size='large' content="I understand & agree to the above" onClick={() => closeModal()} />
                            </div>
                        </div>
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default StabbilitaModal;