export const AdvertCancelReasons: Array<{
    value: string,
    key: string,
    text: string,
}> =
    [
        {
            "value": "other",
            "key": "other",
            "text": "Other",
        }
    ]