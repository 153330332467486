import { observer } from "mobx-react-lite";
import React, { Fragment, useContext } from "react";
import {
  Checkbox,
  Icon,
  Image,
  Label,
  Menu,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { IAssociation } from "../../interfaces/association";
import { IProduct } from "../../interfaces/product";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { CSSTransition } from "react-transition-group";
import AssociationProductTableRow from "./AssociationProductTableRow";
import { OpenAssociationModal } from "./functions/AssociationHelpers";
import { ButtonTypes } from "../confirmationModal/functions/ButtonTypes";
import { Link } from "react-router-dom";
import Associations from "./Associations";

interface IProps {
  association: IAssociation;
  cheapest?: IProduct;
  mode?: "stabbilita_select" | "select" | "group_select" | "group_merge" | "temporary" | undefined;
}

const AssociationCard: React.FC<IProps> = ({ association, cheapest, mode }) => {
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const { openModal } = context.modalStore;
  const { isAdmin } = context.userStore;
  const { removeFromAssociation } = context.associationStore;
  const {
    addToAssociation,
    mergeToAssociation,
    deleteAssociation,
    editingGroupId,
    setEditingGroupId,
    setOriginalAssociationIdToMerge,
    stabbilitaUpdateAssociation,
    mergeToReceiver,
    removeTemporaryFromAssociation
  } = context.productAssociationStore;

  const [showDiscontinued, setShowDiscontinued] = React.useState(false);
  const { openConfirmationModal, closeConfirmationalModal } =
    context.confirmationModalStore;
  const [counts] = React.useState(association.products.filter(f => !f.discontinued).reduce((p: any, c: any) => {
    var name = c['marketId'];
    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
    p[name]++;
    return p;
  }, {}))
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div className="fxDisplay fxDirCol productCardItem ui card">
      <div className="fxDisplay fxFixed">
        <Image
          src={
            association.imageUrl ??
            `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
          }
          onError={({ currentTarget }: any) => {
            currentTarget.onerror = null;
            currentTarget.src =
              `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
          }}
          rounded
          alt="Product Logo"
          className="fxFixed"
          style={{
            width: "110px",
            maxHeight: "110px",
            alignSelf: "center",
            objectFit: "contain",
          }}
        />
        <div
          className="fxStretch fxDisplay fxJustifyEven fxDirCol"
          style={{ padding: "1em" }}
        >
          <h3>{association.title}</h3>
          <div>
            {association.temporary && <Label content="Temporary" color="red" />}
            {association.products.filter(f => f.discontinued === true).length === association.products.length && <Label content="Discontinued" color="red" basic />}
          </div>

        </div>


        <hr />
        {isAdmin && Object.keys(counts).filter(f => counts[f] > 1).length > 0 && <Fragment>
          <div style={{ padding: "1.1em 1.5em", width: "30%" }}>
            Double :
            {Object.keys(counts).filter(f => counts[f] > 1).map(x => <p>{x}</p>)}
          </div>
          <hr /></Fragment>}
        <div
          className="fxFixed leftSection"
          style={{ padding: "1.1em 1.5em", width: "30%" }}
        >
          {cheapest !== undefined ? (
            <Fragment>
              Missing :
              <p>
                {markets.filter(m => !association.products.filter(f => !f.discontinued).map(x => (x.marketId)).includes(m.type_id)).map(x => x.title).join(",")}
              </p>
            </Fragment>
          ) : (
            <Fragment>
              Not available/grouped for the selected store. Please try the
              product search <Link to="/">here</Link>
            </Fragment>
          )}
        </div>
        <hr />
        <nav
          className={`fxFixed fxDisplay fxDirCol ${editingGroupId === association.id ? "" : "fxJustifyEven"
            }`}
          style={
            editingGroupId === association.id
              ? { padding: "0", margin: "0" }
              : { paddingLeft: "0.5em", paddingRight: "0.5em" }
          }
        >
          {isAdmin && (
            <Fragment>
              {mode === "select" ||
                mode === "group_select" ||
                mode === "stabbilita_select" ||
                mode === "group_merge" ? (
                <Fragment>
                  {mode === "stabbilita_select" && (
                    <Popup
                      trigger={
                        <Icon.Group
                          size="large"
                          onClick={() => {
                            stabbilitaUpdateAssociation(association.id);
                          }}
                        >
                          <Icon name="object group outline" color="blue" />
                          <Icon corner="top right" color="green" name="add" />
                        </Icon.Group>
                      }
                      content={"Add to association"}
                      on="hover"
                    />
                  )}
                  {mode === "select" && (
                    <Popup
                      trigger={
                        <Icon.Group
                          size="large"
                          onClick={() => {
                            addToAssociation(association.id);
                          }}
                        >
                          <Icon name="object group outline" color="blue" />
                          <Icon corner="top right" color="green" name="add" />
                        </Icon.Group>
                      }
                      content={"Add to association"}
                      on="hover"
                    />
                  )}
                  {mode === "group_select" && (
                    <Popup
                      trigger={
                        <Icon.Group
                          size="large"
                          onClick={() => {
                            mergeToAssociation(association.id);
                          }}
                        >
                          <Icon name="object group" color="orange" />
                          <Icon corner="top right" color="green" name="add" />
                        </Icon.Group>
                      }
                      content={"Merge to association"}
                      on="hover"
                    />
                  )}
                  {mode === "group_merge" && (
                    <Popup
                      trigger={
                        <Icon.Group
                          size="large"
                          onClick={() => {
                            mergeToReceiver(association.id);
                          }}
                        >
                          <Icon name="object group" color="orange" />
                          <Icon corner="top right" color="green" name="add" />
                        </Icon.Group>
                      }
                      content={"Merge to association"}
                      on="hover"
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <Popup
                    trigger={
                      <Icon
                        name="delete"
                        color="red"
                        onClick={() => {
                          openConfirmationModal(
                            "Confirm association deletion",
                            "Are you sure you want to delete this association?",
                            () =>
                              deleteAssociation(association.id).then(() =>
                                closeConfirmationalModal()
                              ),
                            "Cancel",
                            "Delete",
                            ButtonTypes.None,
                            ButtonTypes.Negative
                          );
                        }}
                      />
                    }
                    content={"Edit association"}
                    on="hover"
                  />
                  <Popup
                    trigger={
                      <Icon
                        name="external alternate"
                        color="brown"
                        onClick={() => {
                          window.open(`${window.location.origin}/catalogue-admin?associationId=${association.id}`, "_blank")
                        }}
                      />
                    }
                    content={"Edit association"}
                    on="hover"
                  />
                  <Popup
                    trigger={
                      <Icon
                        name="compress"
                        color="purple"
                        onClick={() => {
                          setOriginalAssociationIdToMerge(association.id);

                          openModal(
                            <Associations mode="group_merge" />,
                            "large",
                            "Select group to merge into",
                            true,
                            false,
                            () => setOriginalAssociationIdToMerge(undefined)
                          );
                        }}
                      />
                    }
                    content={"Merge to another group"}
                    on="hover"
                  />
                  {mode !== "temporary" && <Popup
                    trigger={
                      <Icon
                        name="pencil"
                        color="blue"
                        onClick={() => {
                          OpenAssociationModal(
                            openModal,
                            association.products,
                            () => { },
                            (...args: []) => { },
                            association.id
                          );
                        }}
                      />
                    }
                    content={"Edit association"}
                    on="hover"
                  />}

                  {mode === "temporary" && <Popup
                    trigger={
                      <Icon
                        name="remove bookmark"
                        color="purple"
                        onClick={() => {
                          removeTemporaryFromAssociation(association.id);
                        }}
                      />
                    }
                    content={"Remove temporary"}
                    on="hover"
                  />}

                  {mode !== "temporary" && <Popup
                    trigger={
                      <Icon
                        name="chart line"
                        color="red"
                        onClick={() => {
                          window.open(`${window.location.origin}/group-analysis?id=${association.id}`, "_blank")
                        }}
                      />
                    }
                    content={"View Analytics"}
                    on="hover"
                  />}
                </Fragment>
              )}
            </Fragment>
          )}

          {editingGroupId !== association.id && isAdmin && (
            <Icon
              fitted
              name={expanded ? "angle up" : "angle down"}
              color="blue"
              size="large"
              onClick={() => {
                setEditingGroupId(undefined);
                setExpanded(!expanded);
              }}
            />
          )}
        </nav>
      </div>
      <CSSTransition
        unmountOnExit
        in={expanded}
        timeout={300}
        classNames="fxDisplay fxDirCol expansion "
      >
        <div>
          <Segment attached="bottom">
            <Menu>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Checkbox toggle checked={showDiscontinued} onClick={() => setShowDiscontinued(!showDiscontinued)} />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Table basic="very" celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Product</Table.HeaderCell>
                  <Table.HeaderCell>Store</Table.HeaderCell>
                  {isAdmin && <Table.HeaderCell>Sku</Table.HeaderCell>}
                  <Table.HeaderCell>Last Update</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  {isAdmin && <Table.HeaderCell>Remove</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {(showDiscontinued ? association.products : association.products.filter(f => !f.discontinued))
                  .slice()
                  .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1))
                  .map((x) => (
                    <AssociationProductTableRow key={x.id} product={x} removeFromAssociation={removeFromAssociation} />
                  ))}
              </Table.Body>
            </Table>
          </Segment>
        </div>
      </CSSTransition>
    </div>
  );
};

export default observer(AssociationCard);
