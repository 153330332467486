import { action, makeObservable, observable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../../../api/agent";
import { getBase64 } from "../../../helpers/urlImageHelper";
import { IAdverForList } from "../../../interfaces/advert";
import { AdImageSizes } from "../../../options/AdImageSizes";
import { RootStore } from "../../../stores/RootStoreContext";

export class AdvertiseSetupStore {
    rootStore: RootStore;

    @observable loading: boolean = false;
    @observable items: IAdverForList[] = [];
    @observable currentPage: number = 0;
    @observable hasMore: boolean = false;
    @observable processingCampaign: boolean = false;
    @observable preview?: string = undefined;
    @observable previewAd?: IAdverForList = undefined;

    @action setPreview = (value?: string) => this.preview = value
    @action setPreviewAd = (value?: IAdverForList) => {
        this.previewAd = value
    }
    @action setLoading = (value: boolean) => { this.loading = value };
    @action setProcessingCampaign = (value: boolean) => { this.processingCampaign = value };
    @action setItems = (value: IAdverForList[]) => {
        var existing = this.items.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.items.push(...missing);
    };
    @action setCurrentPage = (value: number) => { this.currentPage = value };
    @action resetItems = () => { this.items = [] };
    @action setHasMore = (value: boolean) => { this.hasMore = value };

    constructor(rootStore: RootStore) {
        makeObservable(this);

        this.rootStore = rootStore;
    }

    @action dispose = () => {
        this.loading = false;
        this.currentPage = 0;
        this.hasMore = true;
        this.items = [];
        this.processingCampaign = false;
        this.setPreviewAd(undefined);
        this.setPreview(undefined);
    }

    @action submitCampaign = (id: string) => {
        this.setProcessingCampaign(true)
        // agent.Adverts.submit(id).then(() => {
        //     var idx = this.items.findIndex(f => f.id === id)
        //     runInAction(() => {
        //         this.items[idx].status = 1;
        //     })
        //     toast.success(
        //         "We have received your campaign request and we will process it as soon as possible."
        //     );
        //     this.setProcessingCampaign(false)
        // }).catch((ex) => {
        //     // toast.error(JSON.stringify(ex));
        //     this.setProcessingCampaign(false)
        // })
    }

    @action cancelCampaign = (id: string, values: any) => {
        // return agent.Adverts.cancel(id, values).then(() => {
        //     var idx = this.items.findIndex(f => f.id === id)
        //     runInAction(() => {
        //         this.items[idx].status = 5;
        //         this.items[idx].cancelReason = values['cancelReason']
        //         this.items[idx].cancelDescription = values['cancelDescription']
        //     })
        //     this.rootStore.modalStore.closeModal();

        // })
        //     .catch((ex) => { })
        //     .finally(() => {
        //         this.setProcessingCampaign(false)
        //     })
    }

    @action load = async () => {
        try {
            if (this.loading) return;
            this.setLoading(true);

            // var response = await agent.Adverts.get(this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            // this.setHasMore(response.HasMore);
            // this.setItems(response.Items);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };
}