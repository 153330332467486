import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Label, List } from "semantic-ui-react";
import { IShopForListDto } from "../../../interfaces/shop";

interface IProps {
  shop: IShopForListDto;
}

export const ShopListItem: React.FC<IProps> = ({ shop }) => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      // second;
    };
  }, []);

  return (
    <List.Item onClick={() => navigate(`/shop/${shop.id}`)}>
      <List.Content>
        <List.Header style={{ fontSize: "16px", paddingBottom: "6px" }}>
          {shop.title}
        </List.Header>
        <List.Description>
          <span>
            {shop.aprovalStatus === "requested" && (
              <Label color="red" content="Pending Request" />
            )}
            {(shop.aprovalStatus === "requested") &&
              " - "}
            {shop.website && (
              <span
                className="div-as-link"
                onClick={() => {
                  window.open(shop.website, "_blank");
                }}
              >
                {shop.website}
              </span>
            )}
          </span>
        </List.Description>
      </List.Content>
    </List.Item>
  );
};
