import { observer } from 'mobx-react'
import React, { ChangeEvent, Fragment, useContext, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { Menu, Input, Icon, Button, Divider, Card, Segment, Dimmer, Loader } from 'semantic-ui-react';
import { RootStoreContext } from '../../stores/RootStoreContext';
import InlineAdd from '../ads/InlineAdd';
import StabbiltaCard from './components/StabbiltaCard';
import StabbilitaModal from './components/StabbilitaModal';
import { NoResults } from '../../common/NoResults';

const ShoppingCatalogue = () => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext);
    const { markets } = context.cacheStore;
    const { openModal } = context.modalStore;
    const { isAdmin } = context.userStore;
    const {
        dispose,
        load,
        products,
        loading,
        search,
        hasMore,
        orderCheapest,
        changeOrder,
    } = context.stabbiltaStore;
    const { setPage } = context.analyticsStore;
    const { isMobile } = context.deviceStore;

    const [advancedSearch, setAdvanceSearch] = React.useState(false);
    const [timer, setTimer] = React.useState(0);

    useEffect(() => {
        if (!isAdmin)
            openModal(<StabbilitaModal />, "large", undefined, false, false, undefined, "no-padding")

        load();
        setPage("Stabbilta", window.location.pathname);

        return () => {
            dispose();
        };
    }, [
        load,
        navigate,
        dispose,
        openModal,
        setPage,
        isAdmin
    ]);

    return (
        <div className="simply-paddedPage">
            <Fragment>
                <div>
                    <Menu
                        secondary
                        size={isMobile ? "massive" : "small"}
                        style={{ margin: "0 0 5px 0" }}
                    >
                        <Menu.Item
                            className={!isMobile ? "no-padding" : ""}
                            style={{ flex: isMobile ? 1 : "" }}
                        >
                            <Input
                                className="ct-green-text"
                                fluid={isMobile}
                                transparent={isMobile}
                                size={isMobile ? "large" : "small"}
                                icon={
                                    !isMobile ? <Icon name="search" /> : undefined
                                }
                                placeholder={"Search..."}
                                input={
                                    <input className={isMobile ? "" : "ctGreenPlaceholder"} />
                                }
                                onChange={(event: ChangeEvent, data: any) => {
                                    if (!search) return;
                                    if (timer != null) {
                                        clearTimeout(timer);
                                        setTimer(0);
                                    }

                                    setTimer(
                                        setTimeout(
                                            (callback: (text: string) => void, text: string) => {
                                                callback(text);
                                                clearTimeout(timer);
                                            },
                                            500,
                                            search,
                                            data.value
                                        )
                                    );
                                }}
                            />
                        </Menu.Item>

                        {isMobile ? (
                            <Fragment>
                                <Menu.Item icon style={{ margin: 0, paddingRight: "0" }}>
                                    <Icon.Group>
                                        <Icon
                                            fitted
                                            onClick={() => {
                                                setAdvanceSearch(!advancedSearch);
                                            }}
                                            name="filter"
                                            color={advancedSearch ? "blue" : "black"}
                                            disabled={loading}
                                        />
                                        {/* {selectedCategories.length >
                                            0 && (
                                                <Label
                                                    circular
                                                    className="ct-green-text"
                                                    floating
                                                    size="mini"
                                                    style={{
                                                        marginLeft: "1px",
                                                        margingBottom: "1px",
                                                    }}
                                                    onClick={() => setAdvanceSearch(!advancedSearch)}
                                                >
                                                    {selectedCategories.length}
                                                </Label>
                                            )} */}
                                    </Icon.Group>
                                </Menu.Item>
                                <Menu.Item icon style={{ margin: "0px" }}>
                                    <Icon
                                        fitted
                                        onClick={() => {
                                            changeOrder();
                                        }}
                                        name="sort numeric ascending"
                                        color={orderCheapest ? "blue" : "black"}
                                        disabled={loading}
                                    />
                                </Menu.Item>
                            </Fragment>
                        ) : (
                            <Menu.Menu position="right">
                                <Button
                                    className={orderCheapest ? "ct-green-button-active" : "ct-green-button ct-green-text"}
                                    icon={"sort numeric ascending"}
                                    active={orderCheapest}
                                    content={"Order by Price"}
                                    onClick={() => {
                                        changeOrder();
                                    }}
                                />
                            </Menu.Menu>
                        )}
                    </Menu>
                    {/* <CSSTransition
                        unmountOnExit
                        in={advancedSearch}
                        timeout={300}
                        classNames="fluid headerWithSearchAdvancedSearch transition"
                    >
                        <Menu secondary compact={isMobile} vertical>
                            <Menu.Item>
                                <CategoriesFilter
                                    selectedCategories={selectedCategories}
                                    onCategoryClick={(categoryId: string) =>
                                        searchCategory(categoryId)
                                    }
                                />
                            </Menu.Item>
                        </Menu>
                    </CSSTransition> */}
                </div>


                <Divider
                    style={isMobile ? { padding: "0", margin: "0" } : undefined}
                />

                {/* categories filter */}
                {/* {!isMobile && (
                    <Fragment>
                        <CategoriesFilter
                            selectedCategories={selectedCategories}
                            onCategoryClick={(categoryId: string) =>
                                searchCategory(categoryId)
                            }
                        />
                        <Divider
                            style={isMobile ? { padding: "0", margin: "0" } : undefined}
                        />
                    </Fragment>
                )} */}
                {!loading && products.length === 0 && (
                    <NoResults />
                )}
                {(!loading || products.length > 0) && markets.length > 0 ? (
                    <div>
                        <InfiniteScroll
                            style={{ overflow: "hidden" }}
                            dataLength={products.length} //This is important field to render the next data
                            next={() => load()}
                            hasMore={hasMore}
                            loader={loading ? <h4>Loading...</h4> : <div />}
                        >
                            <Card.Group
                                centered
                                className="productPage"
                                itemsPerRow={isMobile ? 1 : undefined}
                                style={{ padding: "5px" }}
                            >
                                {products?.map((x, i) =>
                                    <StabbiltaCard key={`${x.id}_${i}`} product={x} />
                                )}
                                {!hasMore && <InlineAdd key={"last_ad"} />}
                            </Card.Group>
                        </InfiniteScroll>
                    </div>
                ) : (
                    <Segment
                        style={{
                            minHeight: "55vh",
                            boxShadow: "none",
                            border: "none",
                        }}
                    >
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                    </Segment>
                )}
            </Fragment>
        </div>
    );
}

export default observer(ShoppingCatalogue)
