import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { RootStoreContext } from '../../stores/RootStoreContext';
import { Button, Divider, Header, Icon, Input, Menu, Table } from 'semantic-ui-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactJson from 'react-json-view';
import { format, formatDistance } from 'date-fns';
import { NoResults } from '../../common/NoResults';
import { UserDevicesStore } from './functions/UserDevicesStore';

interface IProps {
    userId?: string;
}

const UserDevices: React.FC<IProps> = ({ userId }) => {
    const navigate = useNavigate();
    const context = useContext(RootStoreContext)
    const { isAdmin } = context.userStore;
    const { isMobile } = context.deviceStore;

    const [timer, setTimer] = React.useState(0);

    const {
        items,
        hasMore,
        load,
        loading,
        search,
        dispose } = UserDevicesStore;

    useEffect(() => {
        if (userId)
            search(userId);
        else
            load()
        return () => {
            dispose()
        }
    }, [isAdmin, navigate, dispose, load, userId, search])

    return (
        <div className="simply-paddedPage">
            {!userId && <Menu
                secondary
                size={isMobile ? "massive" : "small"}
                style={{ margin: "0 0 5px 0" }}
            >
                <Menu.Item
                    className={!isMobile ? "no-padding" : ""}
                    style={{ flex: isMobile ? 1 : "" }}
                >
                    <Input
                        fluid={isMobile}
                        transparent={isMobile}
                        size={isMobile ? "large" : "small"}
                        icon={
                            !isMobile ? <Icon name="search" color="green" /> : undefined
                        }
                        placeholder={"User Id/Function/Service..."}
                        input={
                            <input className={isMobile ? "" : "bluePlaceholder"} />
                        }
                        onChange={(event: ChangeEvent, data: any) => {
                            if (!search) return;
                            if (timer != null) {
                                clearTimeout(timer);
                                setTimer(0);
                            }

                            setTimer(
                                setTimeout(
                                    (callback: (text: string) => void, text: string) => {
                                        callback(text);
                                        clearTimeout(timer);
                                    },
                                    500,
                                    search,
                                    data.value
                                )
                            );
                        }}
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        color="green"
                        icon='refresh'
                        basic
                        className="no-margin"
                        onClick={() => {
                            search()
                        }}
                    />
                </Menu.Item>
            </Menu>}

            {!userId && <Divider />}
            <InfiniteScroll
                style={{ overflow: "hidden" }}
                dataLength={items.length} //This is important field to render the next data
                next={() => load()}
                hasMore={hasMore}
                loader={loading ? <h4>Loading...</h4> : <div />}
                endMessage={
                    <p style={{ textAlign: "center" }}>
                        {items.length > 0 ? (
                            <b></b>
                        ) : (
                            <NoResults />
                        )}
                    </p>
                }
            >
                <Table celled  >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            {!userId && <Table.HeaderCell>User</Table.HeaderCell>}
                            <Table.HeaderCell>Token</Table.HeaderCell>
                            <Table.HeaderCell>Platform</Table.HeaderCell>
                            <Table.HeaderCell>Model</Table.HeaderCell>
                            <Table.HeaderCell>OS Version</Table.HeaderCell>
                            <Table.HeaderCell>API Level</Table.HeaderCell>
                            <Table.HeaderCell>Additional Info</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {items.map((x) => {
                            return (
                                <Table.Row key={x.id}>
                                    <Table.Cell>
                                        {formatDistance(new Date(x.lastUpdated * 1000), new Date())} ago
                                        <p>{(x.lastUpdated ?? 0) > 0 ? format(new Date(x.lastUpdated! * 1000), "dd/MM/yyyy HH:mm") : "-"}</p>
                                    </Table.Cell>
                                    {!userId && <Table.Cell style={{
                                        color: "green",
                                        textDecoration: "underline"
                                    }} className='clickable' onClick={() =>
                                        window.open(`${window.location.origin}/manage-user/${x.userId}`, '_blank', 'noopener,noreferrer')}>
                                        <Header>
                                            {`${x.name} ${x.lastName}`}
                                            <Header.Subheader>
                                                {x.userId}
                                            </Header.Subheader>
                                        </Header>
                                    </Table.Cell>}
                                    <Table.Cell>{`${x.token.substring(0, 10)}...`}<Icon name="copy" onClick={() => navigator.clipboard.writeText(x.token)} /></Table.Cell>
                                    <Table.Cell>{x.device?.platform ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.device?.model ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.device?.os_version ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.device?.api_level ?? "-"}</Table.Cell>
                                    <Table.Cell>{x.device?.additional_info && <ReactJson
                                        src={x.device?.additional_info ?? {}}
                                    />}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </InfiniteScroll>
        </div >
    )
}

export default observer(UserDevices);