import { observer } from "mobx-react-lite";
import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Divider,
  Header,
  Label,
  List,
  Segment,
  Statistic,
  Table,
} from "semantic-ui-react";
import { IAdverForAdminList } from "../../interfaces/advert";
import { AdvertCancelReasons } from "../../options/AdvertCancelReasons";
import { AdvertStatus } from "../../options/AdvertStatus";
import { CancelAdvert } from "../AdvertSetup/CancelAdvert";
import { CSSTransition } from "react-transition-group";
import { RootStoreContext } from "../../stores/RootStoreContext";
import { format, formatDistance } from "date-fns";
import { currencyFormat } from "../products/functions/productHelper";
import agent from "../../api/agent";
import { getBase64 } from "../../helpers/urlImageHelper";

interface IProps {
  x: IAdverForAdminList;
}

const AdminCampaignListItem: React.FC<IProps> = ({ x }) => {
  const context = useContext(RootStoreContext);
  const { openModal } = context.modalStore;
  const { isMobile } = context.deviceStore;
  const { approveCampaign, completeCampaign } = context.adminCampaignStore;
  const { setPreviewAd, setPreview, preview } = context.advertiseSetupStore;
  const [expanded, setExpanded] = useState(false);

  return (
    <List.Item>
      <List.Content floated="right">
        <Button
          size="large"
          className="button-as-link greenColor"
          content="Preview"
          onClick={() => {
            if (preview) {
              setPreview(undefined);
              setPreviewAd(undefined);
            } else {
              // check image expiration and delete it accordingly
              try {
                var currentDate = new Date().getTime() / 1000;
                for (var i = 0; i < window.localStorage.length; i++) {
                  try {
                    var item = JSON.parse(
                      window.localStorage.getItem(window.localStorage.key(i)!)!
                    );
                    if (currentDate > item.expiry)
                      window.localStorage.removeItem(
                        window.localStorage.key(i)!
                      );
                  } catch (error) {
                    //ignore the error as we use other items too
                  }
                }
              } catch (error) {
                console.log(error);
              }

              x.files.forEach((file) => {
                // check local storage
                var data = window.localStorage.getItem(file.fileName);

                // if filename not in local store then call aws and save it to local store
                if (!data)
                  agent.AWS.Bucket.getFileUrl(file.fileName).then((dataUrl) =>
                    fetch(dataUrl).then((r) => {
                      r.blob().then((blobFile) =>
                        getBase64(blobFile, (base64: string) => {
                          localStorage.setItem(
                            file.fileName,
                            JSON.stringify({
                              value: base64,
                              expiry: new Date().getTime() / 1000 + 86400 * 3,
                            })
                          );

                          setPreviewAd(x);
                          setPreview(
                            x.files.filter((f: any) =>
                              x.size === "small"
                                ? f.key === "31"
                                : f.key === "61"
                            )[0].fileName
                          );
                        })
                      );
                    })
                  );
                else {
                  setPreviewAd(x);
                  setPreview(
                    x.files.filter((f: any) =>
                      x.size === "small" ? f.key === "31" : f.key === "61"
                    )[0].fileName
                  );
                }
              });
            }
          }}
        />
        {x.status === 1 && (
          <Button
            icon="check circle"
            size="large"
            className="button-as-link greenColor"
            onClick={() => approveCampaign(x.id)}
            title="Approve"
          />
        )}
        <Button
          icon="user"
          size="large"
          className="button-as-link tealColor"
          as={Link}
          to={`/manage-user/${x.userId}`}
          title="User"
        />
        {[1, 2].includes(x.status) && (
          <Button
            icon="remove circle"
            size="large"
            className="button-as-link redColor"
            onClick={() =>
              openModal(
                <CancelAdvert campaignId={x.id} />,
                "mini",
                `Cancel Campaing : ${x.title}`
              )
            }
            title="Cancel"
          />
        )}
        <Button
          icon="edit"
          size="large"
          className="button-as-link blueColor"
          as={Link}
          to={`/edit-campaign/${x.id}`}
          title="Manage"
        />
        {x?.stats && (
          <Button
            icon="info circle"
            size="large"
            className="button-as-link blueColor"
            title="Info"
            onClick={() => setExpanded(!expanded)}
          />
        )}
        {x?.stats &&
          x?.status !== 5 &&
          x?.stats.length > 0 &&
          x.stats[x.stats.length - 1].date + 86400 <
          new Date().getTime() / 1000 && (
            <Button
              icon="circle"
              size="large"
              className="button-as-link redColor"
              title="Mark complete"
              onClick={() => completeCampaign(x.id)}
            />
          )}
      </List.Content>
      <List.Content>
        <List.Header>
          <Label
            basic
            color={AdvertStatus.filter((f) => f.value === x.status)[0].color}
          >
            {AdvertStatus.filter((f) => f.value === x.status)[0].text}
          </Label>
          - {x.title}
          {x.stats &&
            x?.status !== 5 &&
            x?.stats.length > 0 &&
            (x.stats[0].date > new Date().getTime() / 1000 ? (
              <span style={{ color: "blue" }}>
                {`- Starts on ${format(
                  x.stats[0].date * 1000,
                  "EEEE, do MMMM yyyy"
                )}`}
              </span>
            ) : (
              <span style={{ color: "green" }}>
                -{" "}
                {formatDistance(
                  (x.stats[x.stats.length - 1].date + 86400) * 1000,
                  new Date()
                )}{" "}
                remaining
              </span>
            ))}
        </List.Header>
        <List.Description style={{ marginTop: "3px" }}>
          {x.status === 5 ? (
            `${AdvertCancelReasons.filter((f) => f.key === x.cancelReason)[0]
              .text
            } - ${x.cancelDescription ?? "-"}`
          ) : (
            <Fragment>{`${x.budget}€ for ${x.duration} days`}</Fragment>
          )}
        </List.Description>
      </List.Content>

      <CSSTransition
        unmountOnExit
        in={expanded}
        timeout={300}
        classNames="fxDisplay fxDirCol expansion "
      >
        <div>
          <Segment attached="bottom">
            <div style={{ padding: "12px" }}>
              <Header content="Campaign Totals" />
              <Divider />
              {isMobile ? (
                <List divided>
                  <List.Item>
                    <List.Content>
                      <span style={{ color: "black" }}>Impressions : </span>

                      {x?.stats?.reduce((a, b) => a + b.impressions, 0)}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <span style={{ color: "black" }}>Clicks : </span>

                      {x?.stats?.reduce((a, b) => a + b.clicks, 0)}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <span style={{ color: "black" }}>Money Spent : </span>

                      {currencyFormat(
                        x?.stats?.reduce(
                          (a, b) => a + (b.startingBalance - b.balance),
                          0
                        ) ?? 0
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <span style={{ color: "black" }}>Duration : </span>

                      {x?.stats?.length}
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <span style={{ color: "black" }}>Budget : </span>

                      {currencyFormat(
                        x?.stats?.reduce((a, b) => a + b.startingBalance, 0) ??
                        0
                      )}
                    </List.Content>
                  </List.Item>
                </List>
              ) : (
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Impressions</Table.HeaderCell>
                      <Table.HeaderCell>Clicks</Table.HeaderCell>
                      <Table.HeaderCell>Money Spent</Table.HeaderCell>
                      <Table.HeaderCell>Duration</Table.HeaderCell>
                      <Table.HeaderCell>Budget</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        {x?.stats?.reduce((a, b) => a + b.impressions, 0)}
                      </Table.Cell>
                      <Table.Cell>
                        {x?.stats?.reduce((a, b) => a + b.clicks, 0)}
                      </Table.Cell>
                      <Table.Cell>
                        {currencyFormat(
                          x?.stats?.reduce(
                            (a, b) => a + (b.startingBalance - b.balance),
                            0
                          ) ?? 0
                        )}
                      </Table.Cell>
                      <Table.Cell>{x?.stats?.length}</Table.Cell>
                      <Table.Cell>
                        {currencyFormat(
                          x?.stats?.reduce(
                            (a, b) => a + b.startingBalance,
                            0
                          ) ?? 0
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
              <Header content="Day by Day Performance" />
              <Divider />
              <List selection verticalAlign="middle" divided>
                {x?.stats
                  ?.filter((f) => f.date < new Date().getTime() / 1000)
                  .slice()
                  .sort((a, b) => (a.date > b.date ? -1 : 1))
                  .map((x, i) => (
                    <List.Item key={`${x.date}_${i}`}>
                      <span style={{ color: "black" }}>
                        {format(x.date * 1000, "EEEE, do MMMM yyyy")}
                      </span>
                      <List.Content floated="right">
                        <Statistic size="tiny">
                          <Statistic.Label>Spent</Statistic.Label>
                          <Statistic.Value>
                            {currencyFormat(x.startingBalance - x.balance)}
                          </Statistic.Value>
                        </Statistic>
                        <Statistic size="tiny">
                          <Statistic.Label>Impressions</Statistic.Label>
                          <Statistic.Value>{x.impressions}</Statistic.Value>
                        </Statistic>
                        <Statistic size="tiny">
                          <Statistic.Label>Clicks</Statistic.Label>
                          <Statistic.Value>{x.clicks}</Statistic.Value>
                        </Statistic>
                      </List.Content>
                    </List.Item>
                  ))}
              </List>
            </div>
          </Segment>
        </div>
      </CSSTransition>
    </List.Item>
  );
};

export default observer(AdminCampaignListItem);
