import { observer } from "mobx-react-lite";
import React, { ChangeEvent, Fragment, useContext, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import {
  Card,
  Divider,
  Menu,
  Input,
  Icon,
  Button,
  Dimmer,
  Loader,
  Segment,
  Table,
  Dropdown,
  DropdownProps,
  Label,
  List,
  Popup,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import ProductTableRow from "./ProductTableRow";
import UnassociatedProductCount from "./UnassociatedProductCount";
import InlineAdd from "../ads/InlineAdd";
import ProductCard from "./components/ProductCard";
import CategoriesFilter from "../mainMenu/CategoriesFilter";
import { NoResults } from "../../common/NoResults";

interface IProps {
  unassociated?: boolean;
  stems?: string
}

const Products: React.FC<IProps> = ({ unassociated = false, stems = undefined }) => {
  const navigate = useNavigate();
  const context = useContext(RootStoreContext);
  const { markets } = context.cacheStore;
  const {
    dispose,
    load,
    products,
    loading,
    search,
    hasMore,
    oneTimeLoad,
    orderCheapest,
    changeOrder,
    searchText,
    discontinued,
    changeDiscontinued,
    searchCategory,
    selectedCategories,
  } = context.productStore;
  const { selectedMarketIds, setSelectedMarketIds } = context.commonStore;
  const { setPage } = context.analyticsStore;
  const { isAdmin, user } = context.userStore;
  const { isMobile } = context.deviceStore;
  const { previewAd } = context.advertiseSetupStore;

  const [view, changeView] = React.useState("grid");
  const [advancedSearch, setAdvanceSearch] = React.useState(false);
  const [timer, setTimer] = React.useState(0);


  useEffect(() => {
    window.scrollTo(0, 0);
    if (unassociated === true && !isAdmin) {
      navigate("/");
      return;
    }
    oneTimeLoad(unassociated);
    if (stems)
      changeView("list");
    load(stems);
    setPage("Products", window.location.pathname);

    return () => {
      dispose();
    };
  }, [
    load,
    navigate,
    dispose,
    oneTimeLoad,
    setPage,
    unassociated,
    previewAd,
    isAdmin,
    stems
  ]);

  return (
    <div className="simply-paddedPage">
      <Fragment>
        {!previewAd && (
          <div>
            <Menu
              secondary
              size={isMobile ? "massive" : "small"}
              style={{ margin: "0 0 5px 0" }}
            >
              <Menu.Item
                className={!isMobile ? "no-padding" : ""}
                style={{ flex: isMobile ? 1 : "" }}
              >
                <Input
                  fluid={isMobile}
                  // disabled={loading || !search}
                  transparent={isMobile}
                  size={isMobile ? "large" : "small"}
                  icon={
                    !isMobile ? <Icon name="search" className="ct-green-text" /> : undefined
                  }
                  placeholder={"Search..."}
                  input={
                    <input className={isMobile ? "" : "ctGreenPlaceholder"} />
                  }
                  onChange={(event: ChangeEvent, data: any) => {
                    if (!search) return;
                    if (timer != null) {
                      clearTimeout(timer);
                      setTimer(0);
                    }

                    setTimer(
                      setTimeout(
                        (callback: (text: string) => void, text: string) => {
                          callback(text);
                          clearTimeout(timer);
                        },
                        500,
                        search,
                        data.value
                      )
                    );
                  }}
                />
              </Menu.Item>
              {!isMobile && !unassociated && (
                <Menu.Item style={{ padding: "0px" }}>
                  <Dropdown
                    placeholder={
                      !user ? "Sign in to filter stores" : "Filter stores"
                    }
                    value={selectedMarketIds}
                    closeOnBlur
                    selection
                    multiple
                    clearable
                    search
                    renderLabel={(
                      item: any,
                      index: number,
                      defaultLabelProps: any
                    ) => {
                      if (index === 0) return { content: item.text };
                      if (index === 1) return { content: item.text };

                      return index === 2
                        ? {
                          color: defaultLabelProps.color,
                          content: `and ${selectedMarketIds.length - 2
                            } more supermarket(s)`,
                        }
                        : undefined;
                    }}
                    onChange={(ev, data: DropdownProps) => {
                      setSelectedMarketIds((data?.value ?? []) as string[]);
                      if (!search) return;
                      if (timer != null) {
                        clearTimeout(timer);
                        setTimer(0);
                      }

                      setTimer(
                        setTimeout(
                          (callback: (text: string) => void, text: string) => {
                            callback(searchText);
                            clearTimeout(timer);
                          },
                          500,
                          search
                        )
                      );
                    }}
                    options={markets
                      ?.slice()
                      .sort((a, b) => (a.title < b.title ? -1 : 1))
                      .map((x) => ({
                        key: x.type_id,
                        text: x.title,
                        value: x.type_id,
                        disabled: user === undefined,
                      }))}
                  />
                </Menu.Item>
              )}
              {unassociated && isAdmin && (
                <Fragment>
                  <Menu.Item>
                    <Menu.Item icon style={{ margin: "0px" }}>
                      <Icon
                        fitted
                        onClick={() => {
                          changeDiscontinued();
                        }}
                        name="broken chain"
                        color={discontinued ? "blue" : "black"}
                        disabled={loading}
                        title="Include discontinued products"
                      />
                    </Menu.Item>
                  </Menu.Item>
                  <Menu.Item>
                    <UnassociatedProductCount />
                  </Menu.Item>
                  <Menu.Item as={Link} to="/products/wizard">
                    <Button
                      content="Wizard"
                      color="green"
                      basic
                      className="no-margin"
                    />
                  </Menu.Item>
                  <Menu.Item style={{ paddingRight: "0px", marginRight: "0px" }}>
                    <div className="fxFixed fxDisplay fxAlignCenter">
                      <div className="catalogue-sort-by-holder">Shops |</div>
                      <Popup
                        on="click"
                        closeOnTriggerClick
                        closeOnDocumentClick
                        closeOnPortalMouseLeave
                        closeOnTriggerBlur
                        closeOnTriggerMouseLeave
                        closeOnEscape
                        position="bottom center"
                        trigger={
                          <div className="fxFixed fxDisplay">
                            <div className="fxStrech catalogue-sort-by-selected">
                              {selectedMarketIds.length === 0 ? "All" : selectedMarketIds.length > 2 ? `${selectedMarketIds.length} shops` :
                                markets.filter(f => selectedMarketIds.includes(f.type_id)).map(x => x.title).join(',')}
                            </div>
                            <Icon name="angle down" />
                          </div>}
                        content={
                          <List size="medium">
                            {markets.filter(f => !f.shutDown)
                              ?.slice()
                              .sort((a, b) => (a.title < b.title ? -1 : 1)).map((f, i) => (
                                <List.Item
                                  key={f.type_id}
                                  onClick={() => {
                                    if (selectedMarketIds.includes(f.type_id)) {
                                      setSelectedMarketIds(selectedMarketIds.filter(x => x !== f.type_id));
                                    }
                                    else {
                                      setSelectedMarketIds([...selectedMarketIds, f.type_id])
                                    }

                                    if (!search) return;
                                    if (timer != null) {
                                      clearTimeout(timer);
                                      setTimer(0);
                                    }

                                    setTimer(
                                      setTimeout(
                                        (callback: (text: string) => void, text: string) => {
                                          callback(searchText);
                                          clearTimeout(timer);
                                        },
                                        500,
                                        search
                                      )
                                    );
                                  }}
                                  className={`${i !== 0 ? "catalogue-sort-by-option" : ""} clickable`}
                                >
                                  <div className={`fxDisplay fxFixed ${selectedMarketIds.includes(f.type_id) ? "ct-green-text-dark" : ""}`}  >
                                    <div style={{ width: "18px", textAlign: "start" }}>{selectedMarketIds.includes(f.type_id) && <Icon name={"check"} />}</div>
                                    <div style={{ marginLeft: "5px" }}>{f.title}</div>
                                  </div>
                                </List.Item>
                              ))}
                          </List>
                        }
                      />
                    </div>
                  </Menu.Item>
                </Fragment>
              )}
              {isMobile ? (
                <Fragment>
                  {!unassociated && (
                    <Fragment>
                      <Menu.Item icon style={{ margin: 0, paddingRight: "0" }}>
                        <Icon.Group>
                          <Icon
                            fitted
                            onClick={() => {
                              setAdvanceSearch(!advancedSearch);
                            }}
                            name="filter"
                            color={advancedSearch ? "green" : "black"}
                            disabled={loading}
                          />
                          {selectedMarketIds.length +
                            selectedCategories.length >
                            0 && (
                              <Label
                                circular
                                color="green"
                                floating
                                size="mini"
                                style={{
                                  marginLeft: "1px",
                                  margingBottom: "1px",
                                }}
                                onClick={() => setAdvanceSearch(!advancedSearch)}
                              >
                                {selectedMarketIds.length +
                                  selectedCategories.length}
                              </Label>
                            )}
                        </Icon.Group>
                      </Menu.Item>
                      <Menu.Item icon style={{ margin: "0px" }}>
                        <Icon
                          fitted
                          onClick={() => {
                            changeOrder();
                          }}
                          name="sort numeric ascending"
                          color={orderCheapest ? "green" : "black"}
                          disabled={loading}
                        />
                      </Menu.Item>
                    </Fragment>
                  )}
                </Fragment>
              ) : (
                !unassociated && (
                  <Menu.Menu position="right">
                    <Button
                      className={orderCheapest ? "ct-green-button-active-toggle" : "ct-green-button ct-green-text"}
                      icon={"sort numeric ascending"}
                      active={orderCheapest}
                      content={"Order by Price"}
                      onClick={() => {
                        changeOrder();
                      }}
                    />
                    <Button
                      className={view === "list" ? "ct-green-button-active-toggle" : "ct-green-button ct-green-text"}
                      icon={view === "grid" ? "list" : "grid layout"}
                      active={view !== "grid"}
                      content={view === "grid" ? "List View" : "Card View"}
                      onClick={() => {
                        changeView(view === "grid" ? "list" : "grid");
                      }}
                    />
                  </Menu.Menu>
                )
              )}
            </Menu>
            <CSSTransition
              unmountOnExit
              in={advancedSearch}
              timeout={300}
              classNames="fluid headerWithSearchAdvancedSearch transition"
            >
              <Menu secondary compact={isMobile} vertical>
                <Menu.Item>
                  <Dropdown
                    placeholder={
                      !user ? "Sign in to filter stores" : "Filter stores"
                    }
                    value={selectedMarketIds}
                    closeOnBlur
                    selection
                    multiple
                    fluid
                    clearable
                    search
                    style={{ marginLeft: "0px", marginRight: "10px" }}
                    renderLabel={(
                      item: any,
                      index: number,
                      defaultLabelProps: any
                    ) => {
                      if (index === 0) return { content: item.text };
                      if (index === 1) return { content: item.text };

                      return index === 2
                        ? {
                          color: defaultLabelProps.color,
                          content: `and ${selectedMarketIds.length - 2
                            } more supermarket(s)`,
                        }
                        : undefined;
                    }}
                    onChange={(ev, data: DropdownProps) => {
                      setSelectedMarketIds((data?.value ?? []) as string[]);
                      if (!search) return;
                      if (timer != null) {
                        clearTimeout(timer);
                        setTimer(0);
                      }

                      setTimer(
                        setTimeout(
                          (callback: (text: string) => void, text: string) => {
                            callback(searchText);
                            clearTimeout(timer);
                          },
                          500,
                          search
                        )
                      );
                    }}
                    options={markets
                      ?.slice()
                      .sort((a, b) => (a.title < b.title ? -1 : 1))
                      .map((x) => ({
                        key: x.type_id,
                        text: x.title,
                        value: x.type_id,
                        disabled: user === undefined,
                      }))}
                  />
                </Menu.Item>
                <Menu.Item>
                  <CategoriesFilter
                    selectedCategories={selectedCategories}
                    onCategoryClick={(categoryId: string) =>
                      searchCategory(categoryId)
                    }
                  />
                </Menu.Item>
              </Menu>
            </CSSTransition>
          </div>
        )}
        {!previewAd && (
          <Divider
            style={isMobile ? { padding: "0", margin: "0" } : undefined}
          />
        )}
        {/* categories filter */}
        {!unassociated && !stems && !isMobile && (
          <Fragment>
            <CategoriesFilter
              selectedCategories={selectedCategories}
              onCategoryClick={(categoryId: string) =>
                searchCategory(categoryId)
              }
            />
            <Divider
              style={isMobile ? { padding: "0", margin: "0" } : undefined}
            />
          </Fragment>
        )}
        {!loading && products.length === 0 && (
          <NoResults />
        )}
        {(!loading || products.length > 0) && markets.length > 0 ? (
          <div>
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={products.length} //This is important field to render the next data
              next={() => load()}
              hasMore={hasMore && !previewAd}
              loader={loading ? <h4>Loading...</h4> : <div />}
            >
              {view === "grid" || isMobile ? (
                <Card.Group
                  centered
                  className="productPage"
                  itemsPerRow={isMobile ? 1 : undefined}
                  style={{ padding: "5px" }}
                >
                  {products.map((x, i) =>
                    <ProductCard key={`${x.id}_${i}`} product={x} />
                    // i % inlineOccurence === 0 && i > 0 ? (
                    //   <Fragment key={`${x.id}_${i}_add_fr`}>
                    //     <InlineAdd key={`${x.id}_${i}_add`} />
                    //     <ProductCard key={`${x.id}_${i}`} product={x} />
                    //   </Fragment>
                    // ) : (
                    //   <ProductCard key={`${x.id}_${i}`} product={x} />
                    // )
                  )}
                  {!hasMore && <InlineAdd key={"last_ad"} />}
                </Card.Group>
              ) : (
                <Table basic="very" celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Price</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {products.map((x) => (
                      <ProductTableRow product={x} />
                    ))}
                  </Table.Body>
                </Table>
              )}
            </InfiniteScroll>
          </div>
        ) : (
          <Segment
            style={{
              minHeight: "55vh",
              boxShadow: "none",
              border: "none",
            }}
          >
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        )}
      </Fragment>
    </div>
  );
};

export default observer(Products);
