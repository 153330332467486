import { IMeasurement } from "../interfaces/measurements";

export const generateQuantity = (measurements: IMeasurement[], measurement?: number, measurementUnit?: string): string | undefined => {
    let measurementItem = measurements.find(f => f.key === measurementUnit)?.description ?? "-";
    let valueItem = measurement ?? 0;

    if (valueItem === 0) return undefined;

    let valueItemStr = `${valueItem}`
    if (valueItem >= 1000 && (measurementUnit === "grams" || measurementUnit === "ml")) {
        valueItemStr = (valueItem / 1000).toFixed(2); // shorthand for division and assignment
        switch (measurementUnit) {
            case "grams":
                measurementItem = "kg";
                break;
            case "ml":
                measurementItem = "Lt";
                break;
            default:
                break;
        }
    }

    return `${valueItemStr}${measurementItem}`;
};