import { action, computed, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { AdminModuleType } from "../../../options/AdminModules";

export class AdminPanelStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @action setLoading = (value: boolean) => this.loading = value

    @observable pendingRequests: number = 0;
    @action setPendingRequests = (value: number) => this.pendingRequests = value
    @observable usersCount: number = 0;
    @action setUsersCount = (value: number) => this.usersCount = value;
    // @observable earlyAccessCount: number = 0;
    // @action setEarlyAccessCount = (value: number) => this.earlyAccessCount = value;
    @observable shopRequests: number = 0;
    @action setShopRequests = (value: number) => this.shopRequests = value
    @observable claimRequests: number = 0;
    @action setclaimRequests = (value: number) => this.claimRequests = value
    @observable integrationRequests: number = 0;
    @action setIntegrationRequests = (value: number) => this.integrationRequests = value
    @observable activePromos: number = 0;
    @action setActivePromos = (value: number) => this.activePromos = value


    @action load = async () => {
        try {
            this.setLoading(true);

            const { isOwner, userRoles } = this.rootStore.userStore;

            var requests = []
            if (isOwner || userRoles?.roles?.filter(f => f.module === "marketing_management" && f.hasAccess)) {
                requests.push(agent.AdminAds.getRequestsCount().then((value) => this.setPendingRequests(value)))
                requests.push(agent.PromoCodes.count(true).then((value) => this.setActivePromos(value)))
            }
            if (isOwner || userRoles?.roles?.filter(f => (f.module === "user_management" || f.module === "client_orders") && f.hasAccess))
                requests.push(agent.Users.getCount().then((result) => this.setUsersCount(result)));
            // if (isOwner || userRoles?.roles.filter(f => (f.module === "user_management") && f.hasAccess))
            // requests.push(agent.ExpressInterest.EarlyBird.count().then((value) => this.setEarlyAccessCount(value)));
            if (isOwner || userRoles?.roles?.filter(f => f.module === "shop_management" && f.hasAccess)) {
                requests.push(agent.Shop.getCount().then((value) => this.setShopRequests(value)));
                requests.push(agent.Shop.Claims.getCount().then((value) => this.setclaimRequests(value)));
                requests.push(agent.Shop.Integration.getCount().then((value) => this.setIntegrationRequests(value)));
            }

            if (requests.length > 0) {
                await Promise.allSettled(requests);
            }
        } catch (error) { }
        finally {
            this.setLoading(false);
        }
    }

    @computed get items() {
        const { userRoles, isOwner } = this.rootStore.userStore;
        if (isOwner) {
            return this.allItems;
        }
        if (!userRoles) return []


        return this.allItems.filter(item =>
            item.security.some(moduleType =>
                userRoles?.roles?.some(role =>
                    role.module === moduleType && role.hasAccess === true)));
    }

    @action get_counter = (name: string) => {
        switch (name) {
            case 'activePromos':
                return this.activePromos
            case 'pendingRequests':
                return this.pendingRequests
            case 'usersCount':
                return this.usersCount
            case 'shopRequests':
                return this.shopRequests
            case 'claimRequests':
                return this.claimRequests
            case 'integrationRequests':
                return this.integrationRequests
            // case 'earlyAccessCount':
            //     return this.earlyAccessCount
            default:
                return 0;
        }
    }

    @observable allItems: {
        hasColor?: boolean;
        header: string;
        description?: string;
        type: string;
        meta?: string;
        metaCode?: string
        navigate: string;
        security: AdminModuleType[]
    }[] = ([
        {
            header: "VAT Categories",
            description: "All vat categories per country.",
            navigate: "/vat-categories",
            security: ["config_management"],
            type: "others",
        },
        {
            hasColor: true,
            header: "Shopping Carts",
            description: "A list with all carts of the users.",
            navigate: "/user-shopping-carts",
            security: ["user_management"],
            type: "owner",
        },
        {
            hasColor: true,
            header: "User Requests",
            description: "A list with all recent user requests.",
            navigate: "/user-requests",
            security: ["user_management"],
            type: "owner",
        },
        {
            hasColor: true,
            header: "Devices",
            description: "A list with all user devices.",
            navigate: "/user-devices",
            security: ["user_management"],
            type: "owner",
        },
        {
            hasColor: true,
            header: "Promotional Codes",
            description: "A list with all promotional codes.",
            metaCode: 'activePromos',
            meta: `[[activePromos]] active codes`,
            navigate: "/promo-codes",
            security: ["marketing_management"],
            type: "owner",
        },
        {
            hasColor: true,
            header: "Campaign Requests",
            description: "A list with all campaign requests.",
            metaCode: 'pendingRequests',
            meta: `[[pendingRequests]] pending requests`,
            navigate: "/campaigns",
            security: ["marketing_management"],
            type: "owner",
        },
        {
            header: "Users",
            description: "A list with all registered users.",
            metaCode: 'usersCount',
            meta: `[[usersCount]] registered users`,
            navigate: "/users",
            security: ["client_orders", "user_management"],
            type: "owner",
        },
        {
            header: "Shops",
            hasColor: true,
            description: "A list with all registered shops.",
            metaCode: 'shopRequests',
            meta: `[[shopRequests]] pending request`,
            navigate: "/shops",
            security: ["shop_management"],
            type: "owner",
        },
        {
            header: "Claim Requests",
            hasColor: true,
            description: "A list with all claimed requests for the shops.",
            metaCode: 'claimRequests',
            meta: `[[claimRequests]] pending request`,
            navigate: "/claim-requests",
            security: ["shop_management"],
            type: "owner",
        },
        {
            header: "Integration Requests",
            hasColor: true,
            description: "A list with all integration requests.",
            metaCode: 'integrationRequests',
            meta: `[[integrationRequests]] pending request`,
            navigate: "/integration-requests",
            security: ["shop_management"],
            type: "owner",
        },
        {
            header: "Early Access Requests",
            description: "A list with all the users that requested early access.",
            // metaCode: 'earlyAccessCount',
            // meta: `[[earlyAccessCount]] users`,
            navigate: "/early-access-users",
            security: ["user_management"],
            type: "owner",
        },
        {
            header: "HTML Templates",
            description: "Configure templates for receipts, invoices and emails",
            navigate: "/html-templates",
            security: ["config_management", "marketing_management"],
            type: "owner",
        },
        {
            header: "Reports",
            description: "A list with reports.",
            navigate: "/reports",
            type: "owner",
            security: ["reports"],
        },
        {
            header: "All Products",
            description: "All the products of all shops",
            navigate: "/products/all",
            type: "product_grouping",
            security: ["product_management"],
        },
        {
            header: "Un-Associated Products",
            description: "All the products that do not belong in an association.",
            navigate: "/products/unassociated",
            type: "product_grouping",
            security: ["product_management"],
        },
        {
            header: "Temporary Associations",
            description: "All the products we created a temporary association till we group them.",
            navigate: "/products/temporary",
            type: "product_grouping",
            security: ["product_management"],
        },
        {
            header: "Possible Associations - V2",
            description: "All the groups that are possible associations.",
            navigate: "/groups/possible-v2",
            type: "product_grouping",
            security: ["product_management"],
        },
        {
            header: "Labels",
            description: "All the labels of all products.",
            navigate: "/labels-list",
            type: "product_grouping",
            security: ["product_management"],
        },
        {
            header: "Hub Orders",
            description:
                "Create, Review and Receive hub orders",
            navigate: "/hub-orders",
            type: "orders",
            security: ["hub_orders"],
        },
        {
            header: "Orders monitoring",
            description: "All the active orders from the shops",
            navigate: "/orders-monitoring",
            type: "orders",
            security: ["pack_orders"],
        },
        {
            header: "All Client Orders",
            description:
                "All the orders we have received",
            navigate: "/orders",
            type: "orders",
            security: ["client_orders"],
        },
        {
            header: "Catalogue",
            description:
                "See all items that are available for sale",
            navigate: "/catalogue-admin",
            type: "updates",
            security: ["reports", "product_management"],
        },
        {
            header: "Product Analysis",
            description: "Vision & AI for products",
            navigate: "/product-analysis",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Product Categories",
            description: "All the categories we have",
            navigate: "/categories",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Brands",
            description:
                "See and edit all the Brands",
            navigate: "/brands-review",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Last sync",
            description:
                "See all the new items and offers added since last sync",
            navigate: "/last-sync",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Global Inventory",
            description:
                "See all the associations",
            navigate: "/global-inventory",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Group Verification",
            description:
                "See all groups that are not yet verified",
            navigate: "/group-verification",
            type: "updates",
            security: ["product_management"],
        },
        {
            header: "Un-Verified Products",
            description: "All the products that are not verified.",
            navigate: "/products/unverified",
            type: "others",
            security: ["product_management"],
        },
        {
            header: "Products By Weight - Set up",
            description:
                "All the products that are bought by weight but configuration is not set up yet.",
            navigate: "/products/orphan/byWeight",
            type: "others",
            security: ["product_management"],
        },
        {
            header: "Delivery Schedule",
            description: "Configure delivery & pickup time slots",
            navigate: "/delivery-schedule",
            security: ["config_management"],
            type: "orders",
        },
        {
            header: "Bulk Emails",
            description:
                "Send emails to multiple users.",
            navigate: "/marketing-campaigns",
            type: "others",
            security: ["marketing_management"],
        }
    ])
}
