import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect } from "react";
import { useContext } from "react";
import { Visibility } from "semantic-ui-react";
import agent from "../../api/agent";
import { RootStoreContext } from "../../stores/RootStoreContext";

interface IProps {
  isPanel?: boolean;
}

const InlineAdd: React.FC<IProps> = ({ isPanel = false }) => {
  const context = useContext(RootStoreContext);
  // const { getNextInlineItem, getImage, getImageFromName } = context.adStore;
  const { isMobile } = context.deviceStore;
  const { previewAd } = context.advertiseSetupStore;
  // const [campaign, setCampaign] = React.useState(getNextInlineItem());
  const [isVisible, setIsVisible] = React.useState(false);

  function handleActivity(forcedFlag: any) {
    if (previewAd) return;
    if (isVisible) return;

    setIsVisible(forcedFlag);
    // if (forcedFlag) agent.Adverts.view(campaign.id, -1);
  }

  return <div />;
  // return campaign || previewAd ? (
  // <div
  //   className="productCardItem ui card"
  //   onClick={() => {
  //     if (previewAd) return;

  //     agent.Adverts.click(campaign.id, "inline").then(() => {
  //       window.open(
  //         `${campaign.redirectAt}?ctid=${campaign.id}`,
  //         "_blank",
  //         "noopener,noreferrer"
  //       );
  //     });
  //   }}
  // >
  //   <Visibility
  //     onOnScreen={() => handleActivity(true)}
  //     onOffScreen={() => handleActivity(false)}
  //   >
  //     <div className={isPanel ? "adImage-center" : ""}>
  //       <img
  //         alt="Sponsored Images"
  //         src={
  //           previewAd
  //             ? getImageFromName(
  //                 previewAd.files?.filter((f: any) => f.key === "12")[0]
  //                   ?.fileName
  //               )
  //             : getImage(campaign, isPanel)
  //         }
  //         className={`adImage ${!isPanel ? "" : "adImage-shrink"}`}
  //         style={{
  //           maxHeight: isMobile ? "25%" : "20%",
  //         }}
  //       />

  //       <div
  //         style={{
  //           textAlign: "right",
  //         }}
  //         className={"adSponsored"}
  //       >
  //         <span>Sponsored</span>
  //       </div>

  //       <hr />
  //     </div>
  //   </Visibility>
  // </div>
  //   ) : (
  //   <Fragment />
  // );
};

export default observer(InlineAdd);
