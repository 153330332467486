import React, { Fragment, useContext } from "react";
import { Button, Divider, Icon, Image, Label, List, Popup, Transition } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { currencyFormat } from "../../products/functions/productHelper";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import agent from "../../../api/agent";
import { ICatalogueProductForCompare } from "../../../interfaces/product";
import { IStabbiltaForList } from "../../../interfaces/stabbilta";
import Associations from "../../associations/Associations";
import { formatRelative } from "date-fns";
import { ButtonTypes } from "../../confirmationModal/functions/ButtonTypes";

interface IProps {
    product: IStabbiltaForList;
}

const CatalogueCard: React.FC<IProps> = ({ product }) => {
    const context = useContext(RootStoreContext);
    const { isLoggedIn } = context.sessionStore;
    const { isAdmin } = context.userStore;
    const { markets } = context.cacheStore;
    const { openModal } = context.modalStore;
    const { openConfirmationModal, closeConfirmationalModal } = context.confirmationModalStore;
    const {
        tempListForGroup,
        toggleFromList,
        clearTempList,
    } = context.productAssociationStore;

    const [hide] = React.useState(200);
    const [show] = React.useState(500);
    const [expanded, setExpanded] = React.useState(false);
    const [loadingDetail, setLoadingDetail] = React.useState(false);
    const [items, setItems] = React.useState<ICatalogueProductForCompare[]>([]);

    const getOtherItems = async () => {
        if (!isLoggedIn) {
            setExpanded(!expanded);
            setLoadingDetail(false);
            return;
        }
        try {
            setLoadingDetail(true);
            if (items.length > 0) {
                setExpanded(!expanded);
                setLoadingDetail(false);
                return;
            }

            return agent.Stabbilta.getComparison(product.associationId!).then((response) => {
                setItems(response.slice()
                    .sort((a, b) => (a.currentPrice < b.currentPrice ? -1 : 1)));
                setExpanded(true);
                setLoadingDetail(false);
            })

        } catch (error) {
            setLoadingDetail(false);
        }
        finally { }
    }

    return (
        <div className="ui card" style={{ margin: '10px', padding: "7px", maxHeight: "330px", minHeight: "330px" }}>
            <Transition
                animation="scale"
                duration={{ hide, show }}
                visible={!expanded}
            >
                <div style={expanded ? { position: "absolute" } : undefined}>
                    {product.importer && <Label onClick={() => window.open(product.redirect_url, "_blank")} as='a' color='blue' ribbon='right' className="stabbilta-label">
                        {product.importer}
                    </Label>}
                    <Popup
                        trigger={<Image
                            src={product.image_url}
                            onError={({ currentTarget }: any) => {
                                currentTarget.onerror = null;
                                currentTarget.src =
                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                            }}
                            alt={product.title}
                            className="stabbilta-product-image"
                        />}
                        content={
                            <Image
                                style={{
                                    filter: 'drop-shadow(1px 1px 1px #222)'
                                }}
                                src={product.image_url}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                                }}
                                alt={product.title}
                            />
                        }
                    />

                    <div
                        title={product.title}
                        style={{ height: "38px" }}
                        className="grouped-product-title fxStretch"
                    >
                        {product.title}
                    </div>

                    <div className="fxDisplay fxAlignCenter fxJustifyEven">
                        <div className="fxDisplay fxDirCol fxAlignCenter ">
                            <span className="">RRP Was</span>
                            <div className="original-price">
                                <span className="currency-icon">€</span>
                                <span className="price-value">{currencyFormat(product.originalPrice, false)}</span>
                            </div>
                        </div>
                        <div>
                            <div
                                style={{
                                    color: "green",
                                    textAlign: "center",
                                    fontSize: "13px",
                                    fontWeight: "500",
                                }}
                            >
                                {`- ${currencyFormat(product.originalPrice - product.currentPrice)}`}
                            </div>
                            <div
                                style={{
                                    color: "green",
                                    textAlign: "center",
                                    fontSize: "11px",
                                    fontWeight: "500",
                                    lineHeight: '0.7'
                                }}
                            >
                                {`- ${currencyFormat(((product.originalPrice - product.currentPrice) / product.originalPrice) * 100, false)}%`}
                            </div>
                        </div>
                        <div className="fxDisplay fxDirCol fxAlignCenter ">
                            <span className="">RRP Now</span>
                            <div className="discounted-price" style={{ textAlign: "center" }}>
                                <span className="currency-icon">€</span>
                                <span className="price-value">{currencyFormat(product.currentPrice, false)}</span>
                            </div>
                        </div>

                    </div>
                    {(product.associationId || isAdmin) && <Fragment>
                        <Divider />
                        <div className="fxDisplay fxFixed fxJustifyEven ">
                            {isAdmin &&
                                <Fragment>
                                    {!product.associationId && <Popup
                                        trigger={
                                            <Icon
                                                fitted
                                                name={"compress"}
                                                className="ct-green-text"
                                                size="large"
                                                onClick={() => {
                                                    toggleFromList(product.id);
                                                    if (tempListForGroup.includes(product.id))
                                                        openModal(
                                                            <Associations mode="stabbilita_select" />,
                                                            "large",
                                                            "Select an association",
                                                            true,
                                                            false,
                                                            () => clearTempList()
                                                        );
                                                }}
                                            />
                                        }
                                        content="Add to existing group"
                                        on="hover"
                                        disabled={!tempListForGroup.includes(product.id)}
                                    />}
                                    {product.associationId && <Popup
                                        trigger={
                                            <Icon
                                                fitted
                                                name={"remove"}
                                                color="red"
                                                size="large"
                                                onClick={() => {
                                                    openConfirmationModal(
                                                        "Confirm association deletion",
                                                        "Are you sure you want to delete this association?",
                                                        () =>
                                                            agent.Stabbilta.removeAssociation(product.id).then(() => {
                                                                product.associationId = undefined;
                                                                closeConfirmationalModal()
                                                            }
                                                            ),
                                                        "Cancel",
                                                        "Delete",
                                                        ButtonTypes.None,
                                                        ButtonTypes.Negative
                                                    );
                                                }}
                                            />
                                        }
                                        content="Add to existing group"
                                        on="hover"
                                        disabled={!tempListForGroup.includes(product.id)}
                                    />}
                                    {product.associationId && <Popup
                                        trigger={
                                            <Icon
                                                fitted
                                                name={"external alternate"}
                                                color="orange"
                                                size="large"
                                                onClick={() => {
                                                    window.open(`${window.location.origin}/compare?id=${product.associationId}`, "_blank")
                                                }}
                                            />
                                        }
                                        content="Add to existing group"
                                        on="hover"
                                        disabled={!tempListForGroup.includes(product.id)}
                                    />}
                                </Fragment>}
                            {product.associationId && <Popup
                                trigger={
                                    <span className="clickable" onClick={getOtherItems}>
                                        <Icon name={loadingDetail ? "circle notch" : "exchange"} color="green" loading={loadingDetail} /> Compare Web Price
                                    </span>
                                }
                                content="Compare Web Price"
                                on="hover"
                            />}
                        </div>
                    </Fragment>}
                </div>
            </Transition >
            <Transition
                animation="scale"
                duration={{ hide, show }}
                visible={expanded}
            >
                <div style={!expanded ? { position: "absolute" } : undefined}>
                    <div style={{ textAlign: "center" }}>
                        <Button
                            icon="exchange"
                            content="Turn back"
                            className="button-as-link"
                            onClick={() => setExpanded(!expanded)}
                            style={{ color: "#21973f" }}
                            cont="true"
                        ></Button>
                        <Divider />
                        {product.title}
                    </div>
                    <div
                        style={{
                            overflow: "hidden",
                            overflowY: "auto",
                            maxHeight: "250px",
                        }}
                    >
                        <Divider style={{ marginBottom: "0px" }} />
                        {isLoggedIn && items.length === 0 && <div style={{ marginTop: "15px", textAlign: "center" }}>The product is currently unavailable or no longer in stock.</div>}
                        {!isLoggedIn && <div style={{ marginTop: "15px", textAlign: "center" }}>You need to sign in for free to compare the prices.</div>}
                        <List divided verticalAlign="middle" style={{ marginTop: "0px", paddingTop: "3px" }}>
                            {items.map((x, i) => (
                                <List.Item key={i}>
                                    {markets.filter(f => f.type_id === x.marketId).length > 0 ? <Fragment>
                                        <List.Content floated="right" style={{ paddingTop: "6px" }}>
                                            {currencyFormat(x.currentPrice, true)}
                                        </List.Content>
                                        <Image
                                            src={getMarketImageUrl(
                                                markets.filter((f) => f.type_id === x.marketId)[0]
                                            )}
                                            size="tiny"
                                            className={`inline-superMarketImage`}
                                            alt={
                                                markets.filter((f) => f.type_id === x.marketId)[0].title
                                            }
                                            title={
                                                markets.filter((f) => f.type_id === x.marketId)[0].title
                                            }
                                        />
                                        <div className="fxTextAlignCenter" style={{ margin: "auto", float: "right" }}>
                                            <span className="subTextTwo" style={{ fontSize: "5pt" }}>
                                                Updated&nbsp;
                                                {x.lastUpdated && formatRelative(
                                                    new Date(x.lastUpdated * 1000),
                                                    new Date(),
                                                    {}
                                                )}
                                            </span>
                                        </div>

                                    </Fragment> :
                                        <Fragment>

                                        </Fragment>}
                                </List.Item>
                            ))}
                        </List>
                    </div>
                </div>
            </Transition>
        </div >
    );
}

export default observer(CatalogueCard)