import NotFoundPage from "../features/404/NotFoundPage";
import About from "../features/About/About";
import AdminPanel from "../features/adminOwner/AdminPanel";
import CreateAdCampaign from "../features/AdvertSetup/CreateAdCampaign";
import Offers from "../features/offers/Offers";
import PasswordResetForm from "../features/passwordReset/PasswordResetForm";
import Products from "../features/products/Products";
import ProductWizard from "../features/productWizard/productWizard";
import ProfilePage from "../features/profile/ProfilePage";
import ActivateUser from "../features/registration/ActivateUser";
import Users from "../features/users/Users";
import AdminCampaigns from "../features/adminOwner/AdminCampaigns";
import CampaignPerformance from "../features/AdvertSetup/CampaignPerformance";
import OnlineShopPage from "../features/onlineShop/OnlineShopPage";
import UploadInventoryWizard from "../features/onlineShop/UploadInventoryWizard";
import OrphanProducts from "../features/products/OrphanProducts";
import PossibleAssociationsV2 from "../features/possibleAssociations/PossibleAssociationsV2";
import ShopsList from "../features/adminShops/ShopsList";
import ManageShopAccount from "../features/onlineShop/ManageShopAccount";
import PrivacyPolicy from "../features/legal/PrivacyPolicy";
import Terms from "../features/legal/Terms";
import OnlineShopLandingV2 from "../features/landingPages/OnlineShopLandingV2";
import ClaimRequests from "../features/adminClaims/ClaimRequests";
import IntegrationRequests from "../features/adminIntegration/IntegrationRequests";
import PromoCodesWidget from "../features/promoCodes/PromoCodesWidget";
import AllClientOrders from "../features/clientOrders/AllClientOrders";
import ClientCredits from "../features/credits/ClientCredits";
import Disclaimer from "../features/legal/Disclaimer";
import VerifyProducts from "../features/products/VerifyProducts";
import LabelsDictionary from "../features/labelStats/LabelsDictionary";
import LabelsList from "../features/labelStats/LabelsList";
import LastSync from "../features/lastSync/LastSync";
import GlobalInventory from "../features/onlineShop/Components/GlobalInventory";
import AssociationVerification from "../features/groupVerification/AssociationVerification";
import CatalogueAdmin from "../features/catalogue/CatalogueAdmin";
import BrandsReview from "../features/brands/Brands";
import EarlyAccessUsers from "../features/users/EarlyAccessUsers";
import EmailTemplatesWidget from "../features/email/EmailTemplatesWidget";
import DeliveryScheduler from "../features/deliverySchedule/DeliveryScheduler";
import VATCategories from "../features/VATCategories/VATCategories";
import ReportsList from "../features/reports/ReportsList";
import VatSummaryReport from "../features/reports/components/VatSummaryReport";
import ItemizedSalesReport from "../features/reports/components/ItemizedSalesReport";
import ProfitReport from "../features/reports/components/ProfitReport";
import OrdersMonitoring from "../features/orderMonitoring/OrdersMonitoring";
import AssociationAnalysis from "../features/associations/components/AssociationAnalysis";
import ClientWalletReport from "../features/reports/components/ClientWalletReport";
import Stabbilta from "../features/Stabbilta/stabbilta";
import HubOrders from "../features/hubOrders/HubOrders";
import HubOrderVerification from "../features/hubOrders/HubOrderVerification";
import OrderPreparation from "../features/orderMonitoring/components/OrderPreparation";
import ManageUser from "../features/profile/ManageUser";
import { useContext } from "react";
import { RootStoreContext } from "../stores/RootStoreContext";
import { Navigate, useSearchParams } from "react-router-dom";
import { AdminModuleType } from "../options/AdminModules";
import { toast } from "react-toastify";
import ClientWalletDetailReport from "../features/reports/components/ClientWalletDetailReport";
import MarketingCampaignsWidget from "../features/bulkEmails/CampaignsWidget";
import MarketingCampaignDetail from "../features/bulkEmails/components/MarketingCampaignDetail";
import { MarketingCampaignsStore } from "../features/bulkEmails/functions/CampaignsStore";
import MarketingCampaignInProgress from "../features/bulkEmails/components/MarketingCampaignInProgress";
import ShopOrderDetailWidget from "../features/onlineShop/Components/ShopOrderDetailWidget";
import AssociationsPathDecision from "../features/PathDecision/AssociationsPathDecision";
import ShoppingCatalogue from "../features/ShoppingCatalogue/ShoppingCatalogue";
import UserShoppingCarts from "../features/userShoppingCarts/UserShoppingCarts";
import ShoppingCart from "../features/shopping-list/ShoppingCart";
import ProductCategories from "../features/productCategories/ProductCategories";
import Associations from "../features/associations/Associations";
import ProductAnalysis from "../features/productAnalysis/ProductAnalysis";
import ProductAnalysisDetail from "../features/productAnalysis/components/ProductAnalysisDetail";
import ReceiptsReport from "../features/reports/components/ReceiptsReport";
import UserRequests from "../features/userRequests.tsx/UserRequests";
import UserDevices from "../features/devices/UserDevices";

interface IProps {
  requiredRoles: AdminModuleType[]
}
const ProtectedRoute: React.FC<IProps> = ({ children, requiredRoles }: any) => {
  const context = useContext(RootStoreContext)
  const { isLoggedIn } = context.sessionStore;
  const { isOwner, isAdmin, userRoles } = context.userStore;

  if (!isLoggedIn) {
    toast.error("You need to login prior accessing this resource.")
    return <Navigate to="/" replace />;
  }

  if (isOwner)
    return children;
  if (!isOwner && !isAdmin) {
    toast.error("You don't have rights for this action. Please contact thesystem administrator")
    return <Navigate to="/" replace />;
  }

  if (requiredRoles?.length === 0) {
    return children;
  }

  var allowed = (userRoles?.roles.filter(f => (requiredRoles as AdminModuleType[]).includes(f.module) && f.hasAccess === true)) ?? []
  if (allowed.length > 0) {
    return children;
  }
  else {
    toast.error("You don't have rights for this action. Please contact thesystem administrator")
    return <Navigate to="/" replace />;
  }

};

function RedirectWithParams({ to }: any) {
  let [searchParams] = useSearchParams();

  return (
    <Navigate replace to={`${to}?${searchParams}`} />
  );
}


export const routes = [
  { path: "/", element: <RedirectWithParams to="/online-shop" />, showAds: true },
  { path: "/early-access", element: <RedirectWithParams to="/online-shop" />, showAds: true },
  { path: "/products", element: <RedirectWithParams to="/online-shop" />, showAds: true },
  { path: "/online-shop", element: <ShoppingCatalogue />, showAds: true },
  { path: "/request-new-password/:email", element: <ShoppingCatalogue /> },
  { path: "/about", element: <About />, showAds: true },
  {
    path: "/my-shopping-list",
    element: <ShoppingCart />,
    showAds: true,
  },
  {
    path: "/payment/success",
    element: <ShoppingCart />,
    showAds: true,
  },
  {
    path: "/payment/fail",
    element: <ShoppingCart />,
    showAds: true,
  },
  { path: "/activate/:id/:token", element: <ActivateUser /> },
  { path: "/resetPassword/:id/:token", element: <PasswordResetForm /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms", element: <Terms /> },
  { path: "/disclaimer", element: <Disclaimer /> },
  { path: "/price-drop", element: <Offers />, showAds: true },
  { path: "/new-campaign", element: <CreateAdCampaign /> },
  { path: "/edit-campaign/:id", element: <CreateAdCampaign /> },
  { path: "/campaign-performance/:id", element: <CampaignPerformance /> },
  { path: "/groups", element: <RedirectWithParams to="/compare" />, showAds: true },
  { path: "/compare", element: <AssociationsPathDecision />, showAds: true },
  { path: "/user/:userId", element: <ProfilePage /> },
  { path: "/my-profile", element: <ProfilePage />, showAds: true },
  { path: "/join-us", element: <OnlineShopLandingV2 /> },
  { path: "/my-shop", element: <OnlineShopPage mode="new" /> },
  { path: "/claim-shop", element: <OnlineShopPage mode="claim" /> },
  { path: "/shop/:id", element: <ManageShopAccount /> },
  { path: "/shop/:shopId/upload-wizard", element: <UploadInventoryWizard /> },
  { path: "/my-shop/:locationId/orders/:orderId", element: <ShopOrderDetailWidget /> },

  { path: "/global-inventory", element: <GlobalInventory /> },
  {
    path: "/request-integration",
    element: <OnlineShopPage mode="integrate" />,
  },

  { path: "my-orders", element: <AllClientOrders /> },
  { path: "my-credits", element: <ClientCredits /> },
  { path: "stabbilta", element: <Stabbilta /> },

  // ADMIN & OWNER ROUTES
  { path: "/claim-requests", element: <ProtectedRoute requiredRoles={["shop_management"]}><ClaimRequests /></ProtectedRoute> },
  { path: "/user-cart", element: <ProtectedRoute requiredRoles={["user_management"]}><ShoppingCart /></ProtectedRoute> },
  { path: "/integration-requests", element: <ProtectedRoute requiredRoles={["shop_management"]}><IntegrationRequests /></ProtectedRoute> },
  { path: '/catalogue-admin', element: <ProtectedRoute requiredRoles={["product_management"]} ><CatalogueAdmin /></ProtectedRoute> },
  { path: '/product-analysis', element: <ProtectedRoute requiredRoles={["product_management"]} ><ProductAnalysis /></ProtectedRoute> },
  { path: '/product-analysis/:id', element: <ProtectedRoute requiredRoles={["product_management"]} ><ProductAnalysisDetail /></ProtectedRoute> },
  { path: '/brands-review', element: <ProtectedRoute requiredRoles={["product_management"]}><BrandsReview /></ProtectedRoute> },
  { path: "/users", element: <ProtectedRoute requiredRoles={["client_orders", "user_management"]}><Users /></ProtectedRoute> },
  { path: "/products/wizard", element: <ProtectedRoute requiredRoles={["product_management"]}><ProductWizard /></ProtectedRoute> },
  { path: "/adminPanel", element: <ProtectedRoute requiredRoles={[]}> <AdminPanel /></ProtectedRoute> },
  { path: "/products/all", element: <ProtectedRoute requiredRoles={["product_management"]}><Products /></ProtectedRoute> },
  { path: "/products/unassociated", element: <ProtectedRoute requiredRoles={["product_management"]}><Products unassociated={true} /></ProtectedRoute> },
  { path: "/products/temporary", element: <ProtectedRoute requiredRoles={["product_management"]}><Associations mode="temporary" /></ProtectedRoute> },
  { path: "/products/orphan", element: <ProtectedRoute requiredRoles={["product_management"]}><OrphanProducts /></ProtectedRoute> },
  { path: "/products/unverified", element: <ProtectedRoute requiredRoles={["product_management"]}><VerifyProducts /></ProtectedRoute> },
  {
    path: "/products/orphan/byWeight",
    element: <ProtectedRoute requiredRoles={["product_management"]}><OrphanProducts byWeight={true} /></ProtectedRoute>,
  },
  { path: "/groups/possible-v2", element: <ProtectedRoute requiredRoles={["product_management"]}><PossibleAssociationsV2 /></ProtectedRoute> },
  {
    path: "/marketing-campaigns", element: <ProtectedRoute requiredRoles={["marketing_management"]}><MarketingCampaignsWidget store={new MarketingCampaignsStore()} /></ProtectedRoute>
  },
  { path: "/marketing-campaigns/:id", element: <ProtectedRoute requiredRoles={["marketing_management"]}><MarketingCampaignDetail /></ProtectedRoute> },
  { path: "/marketing-campaigns/:id/sending", element: <ProtectedRoute requiredRoles={["marketing_management"]}><MarketingCampaignInProgress /></ProtectedRoute> },
  { path: "/campaigns", element: <ProtectedRoute requiredRoles={["marketing_management"]}><AdminCampaigns /> </ProtectedRoute> },
  { path: "/manage-user/:userId", element: <ProtectedRoute requiredRoles={["user_management", "client_orders"]}><ManageUser /></ProtectedRoute> },
  { path: "/shops", element: <ProtectedRoute requiredRoles={["shop_management"]}><ShopsList /></ProtectedRoute> },
  { path: "/html-templates", element: <ProtectedRoute requiredRoles={["config_management", "marketing_management"]}><EmailTemplatesWidget /></ProtectedRoute> },
  { path: "hub-orders", element: <ProtectedRoute requiredRoles={["hub_orders"]}><HubOrders /></ProtectedRoute> },
  { path: "hub-orders/:id/verify", element: <ProtectedRoute requiredRoles={["hub_orders"]}><HubOrderVerification /></ProtectedRoute> },
  { path: "reports", element: <ProtectedRoute requiredRoles={["reports"]}><ReportsList /></ProtectedRoute> },
  { path: "reports/vat-summary", element: <ProtectedRoute requiredRoles={["reports"]}><VatSummaryReport /></ProtectedRoute> },
  { path: "reports/itemized-sales", element: <ProtectedRoute requiredRoles={["reports"]}><ItemizedSalesReport /></ProtectedRoute> },
  { path: "reports/profit", element: <ProtectedRoute requiredRoles={["reports"]}><ProfitReport /></ProtectedRoute> },
  { path: "reports/wallet", element: <ProtectedRoute requiredRoles={["reports"]}><ClientWalletReport /></ProtectedRoute> },
  { path: "reports/wallet-transactions", element: <ProtectedRoute requiredRoles={["reports"]}><ClientWalletDetailReport /></ProtectedRoute> },
  { path: "reports/receipts", element: <ProtectedRoute requiredRoles={["reports"]}><ReceiptsReport /></ProtectedRoute> },
  { path: "orders-monitoring", element: <ProtectedRoute requiredRoles={["pack_orders"]}><OrdersMonitoring /></ProtectedRoute> },
  { path: 'orders-monitoring/:id/prepare', element: <ProtectedRoute requiredRoles={["pack_orders"]}><OrderPreparation /></ProtectedRoute> },
  { path: 'user-shopping-carts', element: <ProtectedRoute requiredRoles={["user_management"]}><UserShoppingCarts /></ProtectedRoute> },
  { path: 'user-requests', element: <ProtectedRoute requiredRoles={["user_management"]}><UserRequests /></ProtectedRoute> },
  { path: 'user-devices', element: <ProtectedRoute requiredRoles={["user_management"]}><UserDevices /></ProtectedRoute> },
  {
    path: "orders", element: <ProtectedRoute requiredRoles={["pack_orders"]} > <AllClientOrders /></ ProtectedRoute>
  },
  { path: "promo-codes", element: <ProtectedRoute requiredRoles={["marketing_management"]}><PromoCodesWidget /></ProtectedRoute> },
  { path: 'labels-list', element: <ProtectedRoute requiredRoles={["product_management"]}><LabelsList /></ProtectedRoute> },
  { path: 'labels-dictionary', element: <ProtectedRoute requiredRoles={["product_management"]}><LabelsDictionary /></ProtectedRoute> },
  { path: 'last-sync', element: <ProtectedRoute requiredRoles={["product_management"]}><LastSync /></ProtectedRoute> },
  { path: 'group-analysis', element: <ProtectedRoute requiredRoles={["product_management"]}><AssociationAnalysis /></ProtectedRoute> },
  { path: 'categories', element: <ProtectedRoute requiredRoles={["product_management"]}><ProductCategories /></ProtectedRoute> },
  { path: 'group-verification', element: <ProtectedRoute requiredRoles={["product_management"]}><AssociationVerification /></ProtectedRoute> },
  { path: 'delivery-schedule', element: <ProtectedRoute requiredRoles={["config_management"]}><DeliveryScheduler /> </ProtectedRoute> },
  { path: 'vat-categories', element: <ProtectedRoute requiredRoles={["config_management"]}><VATCategories /></ProtectedRoute> },
  { path: 'early-access-users', element: <ProtectedRoute requiredRoles={["user_management"]}><EarlyAccessUsers /></ProtectedRoute> },
  // END ADMIN & OWNER ROUTES

  { path: "/404", element: <NotFoundPage /> },
  { path: "*", element: <NotFoundPage /> },
];
