import React, { useContext } from 'react';
import "./MainBanner.css";

import { Waypoint } from 'react-waypoint';
import Search from '../search/Search';
import { RootStoreContext } from '../../stores/RootStoreContext';

interface Props {
  imageUrl: string;
  onSubmit?: (data: any) => void;
  scroll?: () => void;
  //   intlTitleId: string;
  //   intlDescriptionId: string;
}

export const MainBanner: React.FC<Props> = ({
  imageUrl,
  onSubmit,
  scroll
}) => {
  const context = useContext(RootStoreContext);
  const {
    setSticky,
    removeSticky,
    setDrawerState
  } = context.searchStore;

  const onWaypointPositionChange = ({ currentPosition }: any) => {
    if (!currentPosition || currentPosition === 'above') {
      setDrawerState(false);
      setSticky();
    }
  };
  return (

    <div className="bannerBox">
      <div className="bannerImg" style={{ backgroundImage: `url(${imageUrl})` }} />
      <div className="bannerContent">
        <div className="bannerTitle">
          Make the right choices!
          {/* Boiler plate code for future localization updates */}
          {/* <FormattedMessage
            id={intlTitleId}
            defaultMessage="Set Your Title Through Language File"
            values={{ minute: 90 }}
          /> */}
        </div>
        <div className="bannerDescription">
          Find the best deals in Malta and Gozo.
          {/* Boiler plate code for future localization updates */}
          {/* <FormattedMessage
            id={intlDescriptionId}
            defaultMessage="Set Your Description Through Language File"
          /> */}
        </div>
        <Search
          className="styledInput"
          placeholder={"What can we help you find?"}
          shadow="0 21px 36px rgba(0,0,0,0.05)"
          onSubmit={onSubmit}
          scroll={scroll}
        />
        <Waypoint
          onEnter={removeSticky}
          onLeave={setSticky}
          onPositionChange={onWaypointPositionChange}
        />
      </div>
    </div>
  );
};
