import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useContext, useEffect } from "react";
import {
  Dimmer,
  Divider,
  Icon,
  Input,
  List,
  Loader,
  Menu,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../stores/RootStoreContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShopListItem } from "./components/ShopListItem";

const ShopsList = () => {
  const context = useContext(RootStoreContext);
  const { load, dispose, loading, shops, hasMore, search } =
    context.shopListStore;
  const { isMobile } = context.deviceStore;

  const [timer, setTimer] = React.useState(0);

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  return (
    <div className="simply-paddedPage">
      <Menu
        secondary
        size={isMobile ? "massive" : "small"}
        style={{ margin: "5px 0" }}
      >
        <Menu.Item
          className={!isMobile ? "no-padding" : ""}
          style={{ flex: isMobile ? 1 : "" }}
        >
          <Input
            fluid={isMobile}
            // disabled={loading || !search}
            transparent={isMobile}
            size={isMobile ? "large" : "small"}
            icon={!isMobile ? <Icon name="search" color="blue" /> : undefined}
            placeholder={"Search..."}
            input={<input className={isMobile ? "" : "bluePlaceholder"} />}
            onChange={(event: ChangeEvent, data: any) => {
              if (!search) return;
              if (timer != null) {
                clearTimeout(timer);
                setTimer(0);
              }

              setTimer(
                setTimeout(
                  (callback: (text: string) => void, text: string) => {
                    callback(text);
                    clearTimeout(timer);
                  },
                  500,
                  search,
                  data.value
                )
              );
            }}
          />
        </Menu.Item>
      </Menu>
      <Divider />

      {!loading || shops.length > 0 ? (
        <div>
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={shops.length} //This is important field to render the next data
            next={() => load()}
            hasMore={hasMore}
            loader={loading ? <h4>Loading...</h4> : <div />}
          >
            <List
              divided
              relaxed
              style={isMobile ? { padding: "7px" } : undefined}
              selection
            >
              {shops.map((x) => (
                <ShopListItem key={x.id} shop={x} />
              ))}
            </List>
          </InfiniteScroll>
        </div>
      ) : (
        <Segment>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      )}
    </div>
  );
};

export default observer(ShopsList);
