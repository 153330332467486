import { observer } from "mobx-react-lite";
import { Fragment, useContext, useEffect } from "react";
import { FORM_ERROR } from "final-form";
import { combineValidators, composeValidators, createValidator, isRequired } from "revalidate";
import { Form as FinalForm, Field } from "react-final-form";
import {
  Dimmer,
  Loader,
  Segment,
  Image,
  Form,
  Button,
  Breadcrumb,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { LoadingParagraph } from "../../../components/LoadingParagraph";
import TextInput from "../../../form/TextInput";
import ErrorMessage from "../../../form/ErrorMessage";
import NumericInput from "../../../form/NumericInput";
import { IProductForUpdate } from "../../../interfaces/product";
import CheckBox from "../../../form/CheckBox";
import SelectInput from "../../../form/SelectInput";

const isFloat = createValidator(
  (message) => (value) => {
    if (value !== undefined && isNaN(value)) {
      return message;
    }
  },
  (field) => "x"
);

const validate = combineValidators({
  itemDesc: isRequired(""),
  currentPrice: composeValidators(isRequired(""), isFloat(""))(""),
  lowPrice: isFloat(""),
});

interface IProps {
  extEditingId?: string
}

const ProductDetailWidget: React.FC<IProps> = ({ extEditingId }) => {
  const context = useContext(RootStoreContext);
  const { isDeliveryEnabled } = context.accountPageStore;
  const { loadingDetail, setEditingId, loadItemDetail, editingItem, saveProduct, locations } =
    context.inventoryStore;

  useEffect(() => {
    loadItemDetail();

    return () => { };
  }, [loadItemDetail, extEditingId]);

  return (
    <Fragment>
      <Breadcrumb>
        <Breadcrumb.Section link onClick={() => setEditingId(undefined)}>Inventory</Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>
          {editingItem !== undefined ? editingItem?.itemDesc : <LoadingParagraph />}
        </Breadcrumb.Section>
      </Breadcrumb>

      <div
        className="fxFixed fxDisplay fxDirCol fxAlignCenter"
      >

        <FinalForm
          onSubmit={(values: IProductForUpdate) =>
            saveProduct(values).catch((error) => ({
              [FORM_ERROR]: error,
            }))
          }
          initialValues={editingItem ?? {}}
          validate={validate}
          render={({
            handleSubmit,
            submitError,
            dirtySinceLastSubmit,
            submitting,
            invalid,
            pristine,
            values,
            form,
          }) => (
            <Form onSubmit={handleSubmit} error>
              {loadingDetail && (
                <Segment>
                  <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                  </Dimmer>
                  <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                </Segment>
              )}

              <div
                style={{ paddingTop: "18px" }}
                className="fxFixed fxDisplay fxDirCol fxAlignCenter"
              >
                <div
                  style={{
                    paddingLeft: "6px",
                    paddingRight: "6px",
                    maxWidth: "250px",
                  }}
                >
                  <Image
                    src={
                      values.imageUrl ??
                      `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`
                    }
                    onError={({ currentTarget }: any) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found.png`;
                    }}
                  />
                </div>
              </div>

              <div style={{ minWidth: "400px", paddingTop: "15px" }}>
                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Product Title"}</label>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      loading={loadingDetail}
                      component={TextInput}
                      name="itemDesc"
                      placeholder={"Product Title"}
                    />
                  )}
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Internal Id"}</label>
                  {loadingDetail ? <LoadingParagraph /> : values.internalId}
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"EAN/SKU"}</label>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      loading={loadingDetail}
                      component={TextInput}
                      name="sku"
                      placeholder={"EAN/SKU"}
                    />
                  )}
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Image URL"}</label>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      loading={loadingDetail}
                      component={TextInput}
                      name="imageUrl"
                      placeholder={"Image URL"}
                    />
                  )}
                </Form.Field>

                {/* <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Stock"}</label>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      loading={loadingDetail}
                      component={NumericInput}
                      name="stock"
                      placeholder={"Stock"}
                    />
                  )}
                </Form.Field> */}
                <Form.Field style={{ textAlign: "left" }}>
                  <label style={{ fontSize: "13px" }}>{"Outlet(s)"}</label>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      loading={loadingDetail}
                      component={SelectInput}
                      name="locations"
                      placeholder={"Select Outlet(s)"}
                      options={locations.map(f => ({
                        key: f.id,
                        value: f.id,
                        text: f.title
                      }))}
                      multiselect={true}
                    />
                  )}
                </Form.Field>

                <Form.Group widths={2}>
                  <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>{"Current Price"}</label>
                    {loadingDetail ? (
                      <LoadingParagraph />
                    ) : (
                      <Field
                        loading={loadingDetail}
                        component={NumericInput}
                        name="currentPrice"
                        placeholder={"Current Price"}
                      />
                    )}
                  </Form.Field>
                  {isDeliveryEnabled && <Form.Field style={{ textAlign: "left" }}>
                    <label style={{ fontSize: "13px" }}>{"Lowest Price"}</label>
                    {loadingDetail ? (
                      <LoadingParagraph />
                    ) : (
                      <Field
                        loading={loadingDetail}
                        component={NumericInput}
                        name="lowPrice"
                        placeholder={"Lowest Price"}
                      />
                    )}
                  </Form.Field>}
                </Form.Group>

                <Form.Field style={{ textAlign: "left" }}>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      name="offerItem"
                      component={CheckBox}
                      type="checkbox"
                      label={
                        <span style={{ color: "green" }}>Is Offer</span>
                      }
                    />
                  )}
                </Form.Field>

                <Form.Field style={{ textAlign: "left" }}>
                  {loadingDetail ? (
                    <LoadingParagraph />
                  ) : (
                    <Field
                      name="discontinued"
                      component={CheckBox}
                      type="checkbox"
                      label={
                        <span style={{ color: "red" }}>Item is discontinued</span>
                      }
                    />
                  )}
                </Form.Field>
              </div>

              {submitError && !dirtySinceLastSubmit && (
                <ErrorMessage error={submitError} />
              )}
              <div style={{ textAlign: "center" }}>
                <Button.Group style={{ paddingTop: "15px" }}>
                  <Button
                    color="blue"
                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                    loading={submitting}
                    content={"Save"}
                  />
                  <Button.Or />
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      setEditingId(undefined);
                    }}
                    content={"Cancel"}
                  />
                </Button.Group>
              </div>
            </Form>
          )}
        />
      </div>
    </Fragment >
  );
};

export default observer(ProductDetailWidget);
