import { observer } from 'mobx-react-lite'
import React, { Fragment, SyntheticEvent, useContext, useEffect, useState } from 'react'
import { Dimmer, Loader, Segment, Image, Form, Button, Table, Icon, Divider } from 'semantic-ui-react';
import { Form as FinalForm, Field } from "react-final-form";
import ErrorMessage from '../../../form/ErrorMessage';
import { LoadingParagraph } from '../../../components/LoadingParagraph';
import TextInput from '../../../form/TextInput';
import CheckBox from '../../../form/CheckBox';
import { combineValidators, isRequired } from 'revalidate';
import { RootStoreContext } from '../../../stores/RootStoreContext';
import { FORM_ERROR } from "final-form";
import LocationIQ from '../../../components/maps/LocationIQ';
import agent from '../../../api/agent';
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import SelectInput from '../../../form/SelectInput';
import { DaysOfWeek, HoursOfDay } from '../../../options/DaysOfWeek';
import { ILocation, ILocationPoint } from '../../../interfaces/locations';

interface IProps {
    locationId?: string;
}

const validate = combineValidators({
    title: isRequired(""),
    address: isRequired(""),
    point: isRequired("")
});


const ShopLocationDetailWidget: React.FC<IProps> = ({ locationId }) => {
    const context = useContext(RootStoreContext);
    const { closeModal } = context.modalStore;
    const { mapKey } = context.locationIQStore;
    const {
        loadingDetail,
        loadDetail,
        saveLocation,
        editingItem,
        disposeDetail,
    } = context.locationsManagementStore;

    const [point, setPoint] = useState<ILocationPoint>()

    useEffect(() => {
        loadDetail(locationId);

        return () => {
            disposeDetail();
        };
    }, [disposeDetail, loadDetail, locationId]);

    return (
        <div>
            <FinalForm
                onSubmit={(values: ILocation) =>
                    saveLocation(values)
                        .catch((error) => ({
                            [FORM_ERROR]: error,
                        }))
                }
                initialValues={editingItem ?? undefined}
                mutators={{
                    ...arrayMutators,
                }}
                validate={validate}
                render={({
                    handleSubmit,
                    submitError,
                    dirtySinceLastSubmit,
                    submitting,
                    invalid,
                    pristine,
                    values,
                    form,
                    form: {
                        mutators: { push, remove },
                    },
                }) => (
                    <Form onSubmit={handleSubmit} error>
                        {loadingDetail && (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>
                        )}


                        {/* title */}
                        <div style={{ minWidth: "350px", paddingTop: "15px" }}>
                            <Form.Field style={{ textAlign: "left" }}>
                                <label style={{ fontSize: "13px" }}>{"Title *"}</label>
                                {loadingDetail ? (
                                    <LoadingParagraph />
                                ) : (
                                    <Field
                                        loading={loadingDetail}
                                        component={TextInput}
                                        name="title"
                                        placeholder={"Title"}
                                    />
                                )}
                            </Form.Field>

                            <Form.Group widths={4}>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Link Auto Sync"}
                                    </label>
                                    {loadingDetail ? <LoadingParagraph /> : <Fragment />}
                                </Form.Field>
                                <Form.Field style={{ textAlign: "right" }} title={"This options is managed by Cheap Trolley, if you have any questions please contact us."}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            type="checkbox"
                                            loading={loadingDetail}
                                            component={CheckBox}
                                            toggle
                                            name="autoSync"
                                        />
                                    )}
                                </Form.Field>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>
                                        {"Enable delivery"}
                                    </label>
                                    {loadingDetail ? <LoadingParagraph /> : <Fragment />}
                                </Form.Field>
                                <Form.Field style={{ textAlign: "right" }} title={"This options is managed by Cheap Trolley, if you have any questions please contact us."}>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            type="checkbox"
                                            loading={loadingDetail}
                                            component={CheckBox}
                                            toggle
                                            name="hasDelivery"
                                        />
                                    )}
                                </Form.Field>
                            </Form.Group>

                            {/* address */}

                            <Form.Group widths={'equal'}>
                                <Form.Field style={{ textAlign: "left" }}>
                                    <label style={{ fontSize: "13px" }}>{"Address *"}</label>
                                    {loadingDetail ? (
                                        <LoadingParagraph />
                                    ) : (
                                        <Field
                                            loading={loadingDetail}
                                            component={TextInput}
                                            name="address"
                                            placeholder={"Address"}
                                        />
                                    )}
                                </Form.Field>
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <Button
                                        color="blue"
                                        basic
                                        // loading={submitting}
                                        content={"Search"}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            if (!mapKey) return;
                                            if (values.address?.trim() !== "" ?? false)
                                                agent.LocationIQ.searchAddress(values.address, mapKey).then((result) => {
                                                    if (result.length > 0)
                                                        setPoint({
                                                            lng: result[0].lon,
                                                            lat: result[0].lat
                                                        })
                                                })
                                        }}
                                    />
                                </div>
                            </Form.Group>

                            <LocationIQ point={editingItem?.point ?? point} updateCenter={(lng, lat) => {
                                form.change("point", {
                                    'lng': lng,
                                    'lat': lat,
                                })
                            }} />

                            <Divider hidden />
                            <Form.Group>
                                <Form.Field width={16}>
                                    <label style={{ fontSize: "13px" }}>
                                        Opening Hours
                                    </label>
                                    <Table celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    Day
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Opening Time
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Closing Time
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    All Day
                                                </Table.HeaderCell>
                                                <Table.HeaderCell />
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            <FieldArray name="businessHours">
                                                {({ fields }) =>
                                                    fields.map((name, index) => (
                                                        <Table.Row key={index}>
                                                            <Table.Cell>
                                                                {loadingDetail ? (
                                                                    <LoadingParagraph lines={1} />
                                                                ) : (
                                                                    <Field
                                                                        component={SelectInput}
                                                                        // options={DaysOfWeek.filter(f => !(values as ILocation)?.businessHours?.map(x => x.dayOfWeek).includes(f.value))}
                                                                        options={DaysOfWeek}
                                                                        name={`${name}.dayOfWeek`}
                                                                        loading={loadingDetail}
                                                                        placeholder={"Select day"}
                                                                    />
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {loadingDetail ? (
                                                                    <LoadingParagraph lines={1} />
                                                                ) : (
                                                                    <Field
                                                                        name={`${name}.openOn`}
                                                                        component={SelectInput}
                                                                        options={HoursOfDay}
                                                                    />
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {loadingDetail ? (
                                                                    <LoadingParagraph lines={1} />
                                                                ) : (
                                                                    <Field
                                                                        name={`${name}.closeOn`}
                                                                        component={SelectInput}
                                                                        options={HoursOfDay}
                                                                    />
                                                                )}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                <Field
                                                                    type="checkbox"
                                                                    loading={loadingDetail}
                                                                    component={CheckBox}
                                                                    toggle
                                                                    name={`${name}.allDay`}
                                                                />
                                                            </Table.Cell>

                                                            <Table.Cell disabled={loadingDetail}>
                                                                <Button
                                                                    disabled={values.businessHours.length === 1}
                                                                    color="red"
                                                                    icon="minus"
                                                                    circular
                                                                    size="mini"
                                                                    onClick={(event: SyntheticEvent) => {
                                                                        remove("businessHours", index);
                                                                        event.preventDefault();
                                                                    }}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    ))
                                                }
                                            </FieldArray>
                                        </Table.Body>

                                        <Table.Footer fullWidth>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan="9">
                                                    <Button
                                                        disabled={loadingDetail}
                                                        floated="right"
                                                        basic
                                                        color="blue"
                                                        onClick={(event: SyntheticEvent) => {
                                                            push("businessHours", {});
                                                            event.preventDefault();
                                                        }}
                                                    >
                                                        <Icon name="add" />{" "}
                                                        Add
                                                    </Button>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                    </Table>
                                </Form.Field>
                            </Form.Group>

                        </div>

                        {submitError && !dirtySinceLastSubmit && (
                            <ErrorMessage error={submitError} />
                        )}

                        <div style={{ textAlign: "center" }}>
                            <Button.Group style={{ paddingTop: "15px" }}>
                                <Button
                                    color="blue"
                                    disabled={(invalid && !dirtySinceLastSubmit) || pristine}
                                    loading={submitting}
                                    content={"Save"}
                                />
                                <Button.Or />
                                <Button
                                    onClick={(event) => {
                                        event.preventDefault();
                                        closeModal();
                                    }}
                                    content={"Cancel"}
                                />
                            </Button.Group>
                        </div>

                    </Form>
                )}
            />

        </div>
    )
}


export default observer(ShopLocationDetailWidget)