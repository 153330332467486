import { action, makeObservable, observable } from "mobx";
import agent from "../../../api/agent";
import { IAdvertForPerformance } from "../../../interfaces/advert";
import { RootStore } from "../../../stores/RootStoreContext";

export class AdPerformanceStore {
    rootStore: RootStore;

    @observable loading: boolean = false;
    @observable item?: IAdvertForPerformance = undefined;

    @action setLoading = (value: boolean) => this.loading = value;
    @action setItem = (value?: IAdvertForPerformance) => this.item = value;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;
    }

    @action dispose = () => {
        this.loading = false;
        this.setItem(undefined)
    }

    @action load = async (id: string) => {
        try {
            if (this.loading) return;
            this.setLoading(true);

            // var response = await agent.Adverts.performance(id);
            // this.setItem(response);
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
        finally {
            this.setLoading(false);
        }

    };
}