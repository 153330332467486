import { action, computed, makeObservable, observable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { RootStore } from "../../../stores/RootStoreContext";
import { IProductListForCreation, IProductListForListDto } from "../../../interfaces/productLists";
import { IAssociationForGlobalIntentory, IAssociationForGlobalIntentoryExport } from "../../../interfaces/association";

export class ProductListStore {
    rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this);
        this.rootStore = rootStore;

    }

    @observable loading: boolean = false;
    @observable currentPage: number = 0;
    @observable currentDataPage: number = 0;
    @observable shopId?: string = undefined;
    @observable hasMore: boolean = false;
    @observable hasDataMore: boolean = false;
    @observable lists: IProductListForListDto[] = []
    @observable downloading: boolean = false;
    @observable discontinued: boolean = false;
    @observable selectedMarketIds: string[] = [];
    @observable selectedProductList: IProductListForListDto | undefined = undefined;
    @observable dropSince: number = -1;
    @observable associations: IAssociationForGlobalIntentory[] = []
    @action resetAssociations = (value: IAssociationForGlobalIntentory[]) => this.associations = value;
    @action setAssociations = (value: IAssociationForGlobalIntentory[]) => {
        var existing = this.associations.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.associations.push(...missing);
    };

    @action setLoading = (value: boolean) => this.loading = value;
    @action setShopId = (value?: string) => this.shopId = value;
    @action setSelectedProductList = (value?: IProductListForListDto) => {
        this.selectedProductList = value;
        if (value === undefined) this.disposeData();
    }
    @action setSelectedMarketIds = (value: string[]) => this.selectedMarketIds = value;
    @action setCurrentPage = (value: number) => this.currentPage = value;
    @action setCurrentDataPage = (value: number) => this.currentDataPage = value;
    @action setHasMore = (value: boolean) => this.hasMore = value;
    @action setHasDataMore = (value: boolean) => this.hasDataMore = value;
    @action setDownloading = (value: boolean) => this.downloading = value;
    @action resetLists = (value: IProductListForListDto[]) => this.lists = value;
    @action setLists = (value: IProductListForListDto[]) => {
        var existing = this.lists.map(f => f.id)
        var missing = value.filter(f => existing.indexOf(f.id) < 0);

        this.lists.push(...missing);
    };

    @action updateList = async (newItem: IProductListForListDto) => {
        return agent.Shop.ProductLists.update(this.shopId!, newItem).then(() => {
            newItem.updatedOn = (new Date()).getTime() / 1000
            newItem.skus = (newItem.skus as any).split(",").length;
            var idx = this.lists.findIndex(f => f.id === newItem.id);
            this.lists[idx] = { ...newItem }
            this.resetLists(this.lists)
        })
    }

    @action deleteList = async (listId: string) => {
        return agent.Shop.ProductLists.delete(this.shopId!, listId).then(() => {
            this.resetLists(this.lists.filter(f => f.id !== listId))
        })
    }

    @action load = async (shopId?: string) => {
        try {
            shopId = shopId ?? this.shopId
            this.setLoading(true);
            this.setShopId(shopId);

            var response = await agent.Shop.ProductLists.get_many(shopId!, this.currentPage);

            this.setCurrentPage(this.currentPage + 1);
            this.setHasMore(response.HasMore);
            this.setLists(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action createList = async (list: IProductListForCreation) => {
        const newListId = await agent.Shop.ProductLists.create(this.shopId!, list);

        runInAction(() => {
            this.lists.unshift({
                id: newListId,
                skus: list.skus.split(",").length,
                title: list.title,
                updatedOn: (new Date()).getTime() / 1000
            });
        });
    }

    @computed get markets() {
        const { account_markets } = this.rootStore.accountPageStore;
        const { markets } = this.rootStore.cacheStore;
        const { isAdmin } = this.rootStore.userStore;

        return isAdmin ? markets : account_markets;
    }

    @action changeDiscontinued = async () => {
        try {
            this.discontinued = !this.discontinued;
            this.search();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action changeDropSince = async (dropSince: number) => {
        try {
            this.dropSince = dropSince;
            this.search();
        } catch (error) {
        }
    };

    @action getColumnsForExportRow = (group: IAssociationForGlobalIntentoryExport, marketId?: string) => {
        var columnsToReturn: string[] = [];

        var skus = Array.from(
            new Set(group.products?.map((z) => z.sku))
        ).filter(f => f && f !== "" && f?.length > 7).join(", ")
        columnsToReturn.push(skus)
        columnsToReturn.push(group.title ?? '')

        const { accountId } = this.rootStore.userStore;

        if (marketId) {
            columnsToReturn.push(group.products.filter(f => f.marketId === marketId)[0]?.currentPrice.toFixed(2))
        }

        (this.selectedMarketIds.length > 0 ?
            this.markets.filter(f => this.selectedMarketIds.includes(f.type_id)) :
            this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId && !f.shutDown).forEach(x => {
                columnsToReturn.push(group.products
                    ?.filter(f => f.marketId === x.type_id)[0]?.currentPrice?.toFixed(2) ?? "");
            });

        return columnsToReturn;
    };

    @action search = async () => {
        try {
            this.resetAssociations([]);
            this.setCurrentDataPage(0)

            this.loadDetail();
        } catch (error) {
            // toast.error(JSON.stringify(error));
        }
    };

    @action loadDetail = async () => {
        try {
            if (!this.selectedProductList) {
                return;
            }

            this.setLoading(true);

            var myMarket = this.markets.filter(f => f.id === this.shopId)[0].type_id

            if (this.selectedMarketIds.length === 1 && myMarket && this.selectedMarketIds[0] === myMarket) {
                this.selectedMarketIds = []
            }
            else if (this.selectedMarketIds.length > 0 && myMarket && !this.selectedMarketIds.includes(myMarket)) {
                this.selectedMarketIds.push(myMarket);
            }

            var response = await agent
                .Shop
                .ProductLists
                .data(
                    this.selectedProductList.id,
                    this.selectedMarketIds,
                    this.shopId,
                    this.discontinued,
                    this.dropSince,
                    this.currentDataPage
                );

            this.setCurrentDataPage(this.currentDataPage + 1);
            this.setHasDataMore(response.HasMore);
            this.setAssociations(response.Items);
        } catch (error) {
        }
        finally {
            this.setLoading(false);
        }

    };

    @action exportDataForCsv = async () => {
        if (!this.selectedProductList) {
            return;
        }

        this.setDownloading(true);
        const { accountId } = this.rootStore.userStore;

        try {
            var myMarket = accountId ? this.markets.filter(f => f.id === accountId)[0].type_id : undefined

            if (this.selectedMarketIds.length > 0 && myMarket) {
                this.selectedMarketIds.push(myMarket);
            }

            var complexData = await agent.Shop.ProductLists.export(
                this.selectedProductList.id,
                this.selectedMarketIds,
                this.shopId,
                this.discontinued,
                this.dropSince,
            );

            var csvData: any[] = [];
            var headers = ["SKU", "Description"]
            if (accountId) {
                headers.push(this.markets.filter(f => f.id === accountId)[0].title)
            }

            (this.selectedMarketIds.length > 0 ?
                this.markets.filter(f => this.selectedMarketIds.includes(f.type_id)) :
                this.markets).slice().sort((a, b) => (a.title < b.title ? -1 : 1)).filter(f => f.id !== accountId).forEach(x => {
                    headers.push(x.title);
                });

            csvData.push(headers);

            complexData.forEach((row: any) => {
                csvData.push(this.getColumnsForExportRow(row, myMarket));
            });

            var today = new Date();
            var fileName = `${today.getFullYear()}_${today.getMonth()}_${today.getDate()}_${today.getHours()}_${today.getMinutes()}_${today.getSeconds()}`;
            return {
                data: csvData,
                filename: `${this.selectedProductList.title}_${fileName}.csv`,
            };
        } catch (error) {
            console.log(error);
        }
        finally {
            this.setDownloading(false);
        }
    };


    @action getList = async (id: string) => {
        return agent.Shop.ProductLists.get(this.shopId!, id);
    }

    @action dispose = () => {
        this.setShopId(undefined);
        this.setLoading(false);
        this.setCurrentPage(0);
        this.resetLists([]);
        this.setHasMore(false);
        this.setSelectedProductList(undefined)
    }

    @action disposeData = () => {
        this.setCurrentDataPage(0);
        this.resetAssociations([]);
        this.setHasDataMore(false);
        this.discontinued = false;
        this.dropSince = -1;
    }
}