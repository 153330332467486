import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, isRequired } from "revalidate";
import { FORM_ERROR } from "final-form";
import { Button, Form } from "semantic-ui-react";
import ErrorMessage from "../../form/ErrorMessage";
import { Fragment, useContext } from "react";
import SelectInput from "../../form/SelectInput";
import TextAreaInput from "../../form/TextAreaInput";
import { AdvertCancelReasons } from "../../options/AdvertCancelReasons";
import { RootStoreContext } from "../../stores/RootStoreContext";

const validate = combineValidators({
  cancelReason: isRequired(""),
  cancelDescription: isRequired(""),
});

interface IProps {
  campaignId: string;
}

export const CancelAdvert: React.FC<IProps> = ({ campaignId }) => {
  const context = useContext(RootStoreContext);
  const { closeModal } = context.modalStore;
  const { isAdmin, isOwner } = context.userStore;
  const { cancelCampaignAdmin } = context.adminCampaignStore;
  const { cancelCampaign } = context.advertiseSetupStore;

  return (
    <div />
    // <FinalForm
    //   validate={validate}
    //   onSubmit={async (values: any) =>
    //     (isAdmin || isOwner
    //       ? cancelCampaignAdmin(campaignId, values)
    //       : cancelCampaign(campaignId, values)
    //     ).catch((error) => ({
    //       [FORM_ERROR]: error,
    //     }))
    //   }
    //   initialValues={{ cancelReason: "other" }}
    //   render={({
    //     handleSubmit,
    //     submitting,
    //     submitError,
    //     dirtySinceLastSubmit,
    //     invalid,
    //   }) => {
    //     return (
    //       <Form onSubmit={handleSubmit} error>
    //         <Fragment>
    //           <Form.Group widths="equal">
    //             <Form.Field>
    //               <label style={{ fontSize: "13px" }}>{"Cancel reason"}</label>
    //               <Field
    //                 allowEdit={false}
    //                 component={SelectInput}
    //                 placeholder="Select a reason"
    //                 options={AdvertCancelReasons}
    //                 name="cancelReason"
    //               />
    //             </Form.Field>
    //           </Form.Group>
    //           <Form.Group widths="equal">
    //             <Form.Field>
    //               <label style={{ fontSize: "13px" }}>{"More details"}</label>
    //               <Field
    //                 name="cancelDescription"
    //                 component={TextAreaInput}
    //                 rows={5}
    //                 placeholder="Please elaborate further on why you are cancelling your ad..."
    //               />
    //             </Form.Field>
    //           </Form.Group>
    //         </Fragment>
    //         {submitError && !dirtySinceLastSubmit && (
    //           <ErrorMessage error={submitError} />
    //         )}
    //         <div style={{ textAlign: "center" }}>
    //           <Button.Group style={{ paddingTop: "15px" }}>
    //             <Button
    //               color="blue"
    //               disabled={invalid}
    //               loading={submitting}
    //               content={"Save"}
    //             />
    //             <Button.Or />
    //             <Button
    //               onClick={(event) => {
    //                 event.preventDefault();
    //                 closeModal();
    //               }}
    //               content={"Cancel"}
    //             />
    //           </Button.Group>
    //         </div>
    //       </Form>
    //     );
    //   }}
    // />
  );
};
