export const AdPanelSize = [
    {
        "value": "small",
        "key": "small",
        "text": "Small",
    }, {
        "value": "large",
        "key": "large",
        "text": "Large",
    }
]