import React, { Fragment, useContext } from "react";
import { Button, Divider, Header, Icon, Image, List, Popup, Transition } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStoreContext";
import { requestUserSession } from "../../registration/functions/sessionFnc";
import { ICatalogueForOrderList } from "../../../interfaces/catalogue";
import { currencyFormat } from "../../products/functions/productHelper";
import { getMarketImageUrl } from "../../../helpers/supermarketImage";
import agent from "../../../api/agent";
import { ICatalogueProductForCompare } from "../../../interfaces/product";
import { generateQuantity } from "../../../helpers/productTitleHelper";

interface IProps {
    product: ICatalogueForOrderList;
}

const CatalogueCard: React.FC<IProps> = ({ product }) => {
    const context = useContext(RootStoreContext);
    const { isMobile } = context.deviceStore;
    const { isLoggedIn } = context.sessionStore;
    const { markets, measurements } = context.cacheStore;
    const { loading, clearing } = context.shoppingCatalogueStore;
    const {
        addToCart,
        removeFromCart,
        getProductQuantity,
        syncCart,
        largeItemLimit,
        bottleLimit
    } = context.shoppingCartStore;
    const { openModal, closeModal } = context.modalStore;

    const [timer, setTimer] = React.useState(0);
    const [hide] = React.useState(200);
    const [show] = React.useState(500);
    const [expanded, setExpanded] = React.useState(false);
    const [loadingDetail, setLoadingDetail] = React.useState(false);
    const [items, setItems] = React.useState<ICatalogueProductForCompare[]>([]);

    const getOtherItems = async () => {
        if (!isLoggedIn) {
            openModal(<div>
                <Header textAlign="center" as="h4">
                    You need to sign in for free to compare the prices!
                    <Header.Subheader>
                        Opening an account with us is completely free and always will be. You can compare prices without any cost, and rest assured, we won’t spam you with advertising.
                    </Header.Subheader>
                </Header>
                <Divider />
                <p style={{ textAlign: "center" }}>
                    <Button.Group>
                        <Button className="ct-green-button-active" content="Sign In" onClick={() => requestUserSession(
                            isMobile,
                            openModal,
                            closeModal,
                            () => {
                                closeModal();
                            }
                        )} />
                        <Button.Or />
                        <Button content="Cancel" onClick={() => closeModal()} />
                    </Button.Group>
                </p>
            </div>, "tiny", undefined, true, false)
            // toast.info("You need to sign in for free to compare the prices!");
            return;
        }
        try {
            setLoadingDetail(true);
            if (items.length > 0) {
                setExpanded(!expanded);
                setLoadingDetail(false);
                return;
            }

            return agent.Catalogue.Order.getComparison(product.associationId!).then((response) => {
                response.push({
                    id: "CheapTrolley",
                    marketId: "1Cheap Trolley",
                    currentPrice: product.prices.filter(f => f.locations.length > 0).slice()
                        .sort((a, b) => (a.price < b.price ? -1 : 1))[0].price
                })
                setItems(response.slice()
                    .sort((a, b) => {
                        // First, compare by currentPrice
                        if (a.currentPrice < b.currentPrice) return -1;
                        if (a.currentPrice > b.currentPrice) return 1;

                        // If currentPrice is the same, then sort by marketId
                        return a.marketId.localeCompare(b.marketId);
                    }));
                setExpanded(true);
                setLoadingDetail(false);

            })

        } catch (error) {
            setLoadingDetail(false);
        }
        finally { }


    }

    const syncCartHandle = () => {
        syncCart(product.id).then(() => clearTimeout(timer))
    }

    const handleChangeValue = (type: 'add' | 'remove') => {
        if (type === "add")
            addToCart(product);
        else
            removeFromCart(product.id);

        if (timer != null) {
            clearTimeout(timer);
            setTimer(0);
        }

        setTimer(
            setTimeout(syncCartHandle, 400, null)
        );
    }

    return (
        <div className={`ui card fade-enter fade-enter-active ${loading && clearing ? "catalogue-animate-out" : "catalogue-animate"}`}
            style={
                isMobile ?
                    { marginTop: "16px", marginBottom: "0px", marginLeft: "6px", marginRight: "6px", padding: "3px", maxHeight: "380px" } :
                    { marginTop: "15px", padding: "7px", maxHeight: "380px" }}
        >
            <Transition
                animation="scale"
                duration={{ hide, show }}
                visible={!expanded}
            >
                <div style={expanded ? { position: "absolute" } : undefined}>
                    {product.free_delivery && <div className="catalogue-delivery">
                        <Popup
                            trigger={<Image src={`${process.env.REACT_APP_CLOUDFRONT}/free_delivery.png`} />}
                            content="Free delivery when purchasing this item!"
                        />
                    </div>}
                    {product.savings && (product.savings ?? 0) > 0 && <div className="discount">
                        <div className="line1">SAVE</div>
                        <div className="line2">
                            {currencyFormat(product.savings)}
                        </div>
                    </div>}
                    <Popup
                        position="left center"
                        className="catalogue-image-popup"
                        trigger={
                            <Image
                                src={product.imageUrl ??
                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
                                }}
                                alt={product.title}
                                className={isMobile ? "grouped-product-image-mobile" : "grouped-product-image"}
                            />
                        }
                        on={"click"}
                        content={
                            <Image
                                src={product.imageUrl ??
                                    `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`}
                                onError={({ currentTarget }: any) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src =
                                        `${process.env.REACT_APP_CLOUDFRONT}/image-not-found-ctl.png`;
                                }}
                                alt={product.title}
                                size="huge"
                                className="grouped-product-image-popup"
                            />
                        }
                    />

                    <div
                        title={product.title}
                        style={{ height: "45px" }}
                        className="grouped-product-title fxStretch"
                    >
                        {`${product.title} - ${generateQuantity(measurements, product.measurment, product.measurementUnit)}`}
                    </div>

                    <div
                        className="fxDisplay fxAlignCenter fxJustifyEven "
                        style={{
                            marginTop: "7px",
                            height: "50px",
                        }}
                    >
                        {product && (
                            <div
                                style={{
                                    fontSize: "19px",
                                    fontWeight: "600",
                                    textAlign: "center",
                                }}
                            >
                                <Icon name="euro" />
                                {currencyFormat(product.prices.filter(f => f.locations.length > 0).slice()
                                    .sort((a, b) => (a.price < b.price ? -1 : 1))[0].price, false)}
                                {product.associationId && <div
                                    className="group-compare-new"
                                    onClick={getOtherItems}
                                >
                                    <Icon name={loadingDetail ? "circle notch" : "exchange"} loading={loadingDetail} />
                                    {loadingDetail ? "Loading" : "Compare"}
                                </div>}
                            </div>
                        )}
                        {/* {product.savings &&
                            <div className="discount">
                                <div className="line1">SAVE</div>
                                <div className="line2">
                                    {currencyFormat(product.savings)}
                                </div>
                            </div>
                        } */}

                    </div>
                    <Divider
                        style={
                            getProductQuantity(product.id) > 0
                                ? { marginBottom: "4px" }
                                : undefined
                        }
                    />

                    <div>
                        {getProductQuantity(product.id) > 0 ? (
                            <div
                                className={`fxDisplay fxFixed fxJustifyBetween`}
                                style={{ paddingLeft: "8px", paddingRight: "8px" }}
                            >
                                <div className={`item-animate minus`}>
                                    <Popup
                                        trigger={
                                            <Button
                                                size="tiny"
                                                circular
                                                className="ct-green-button-active"
                                                icon={"minus"}
                                                onClick={() => {
                                                    handleChangeValue("remove");
                                                }}
                                            />
                                        }
                                        content={"Remove from cart"}
                                        on="hover"
                                    />
                                </div>

                                <div
                                    className={`item-animate number `}
                                    style={{
                                        fontSize: "17px",
                                        fontWeight: "500",
                                        marginTop: "5px",
                                    }}
                                >
                                    {getProductQuantity(product.id)}
                                </div>

                                <div className={`item-animate-fast plus`}>
                                    <Popup
                                        trigger={
                                            <Button
                                                size="tiny"
                                                disabled={(product.largeContainer === true && largeItemLimit) || (product.bottleLimit === true && bottleLimit)}
                                                circular
                                                className="ct-green-button-active"
                                                icon={"plus"}
                                                onClick={() => {
                                                    handleChangeValue("add");
                                                }}
                                            />
                                        }
                                        content={"Add more"}
                                        on="hover"
                                    />
                                </div>
                            </div>
                        ) :
                            <div
                                className={`fxDisplay fxFixed fxJustifyEnd item-fade-out`}
                                style={{ paddingRight: "12px", marginTop: "-5px" }}
                            >
                                <Icon
                                    className="linkable ct-green-text"
                                    size="large"
                                    name={"plus cart"}
                                    disabled={(product.largeContainer === true && largeItemLimit) || (product.bottleLimit === true && bottleLimit)}
                                    onClick={() => {
                                        if (!isLoggedIn)
                                            requestUserSession(
                                                isMobile,
                                                openModal,
                                                closeModal,
                                                () => {
                                                    closeModal();
                                                }
                                            );
                                        else {
                                            handleChangeValue("add");
                                        }
                                    }}
                                ></Icon>
                            </div>
                        }
                    </div>
                </div>
            </Transition >
            <Transition
                animation="scale"
                duration={{ hide, show }}
                visible={expanded}
            >
                <div style={!expanded ? { position: "absolute" } : undefined}>
                    <div style={{ textAlign: "center" }}>
                        <Button
                            icon="exchange"
                            content="Turn back"
                            className="button-as-link"
                            onClick={() => setExpanded(!expanded)}
                            style={{ color: "#21973f" }}
                            cont="true"
                        ></Button>
                        <Divider />
                        {product.title}
                    </div>
                    <div
                        style={{
                            overflow: "hidden",
                            overflowY: "auto",
                            maxHeight: "270px",
                        }}
                    >
                        <Divider style={{ marginBottom: "0px" }} />
                        <List divided verticalAlign="middle" style={{ marginTop: "0px", paddingTop: "3px" }}>
                            {items.map((x, i) => (
                                <List.Item key={i}>
                                    {markets.filter(f => f.type_id === x.marketId).length > 0 ? <Fragment>
                                        <List.Content floated="right" style={{ paddingTop: "6px" }}>
                                            {currencyFormat(x.currentPrice, true)}
                                        </List.Content>
                                        <Image
                                            src={getMarketImageUrl(
                                                markets.filter((f) => f.type_id === x.marketId)[0]
                                            )}
                                            size="tiny"
                                            className={`inline-superMarketImage`}
                                            alt={
                                                markets.filter((f) => f.type_id === x.marketId)[0].title
                                            }
                                            title={
                                                markets.filter((f) => f.type_id === x.marketId)[0].title
                                            }
                                        />
                                    </Fragment> :
                                        <Fragment>
                                            <List.Content floated="right" style={{ paddingTop: "6px" }}>
                                                {currencyFormat(x.currentPrice, true)}
                                            </List.Content>
                                            <Image
                                                src={"/cheaptrolley.svg"}
                                                size="tiny"
                                                className={`inline-superMarketImage`}
                                                alt={
                                                    "Cheap Trolley Logo"
                                                }
                                                title={
                                                    "Cheap Trolley"
                                                }
                                            />
                                        </Fragment>}
                                </List.Item>
                            ))}
                        </List>
                    </div>
                </div>
            </Transition>
        </div >
    );
}

export default observer(CatalogueCard)